import React from 'react';
import DashNavBar from '../elements/DashNavBar';
import DashSearch from './DashSearch';
export default function(props){
    return(
        <>
        <DashNavBar userPack={props.userPack}/>
        <DashSearch userPack={props.userPack} newMethod={()=>{}}/>
        </>
    )
}