import React from 'react';
import TableLine from './TableLine';
import DashNavBar from '../elements/DashNavBar';
import DashSearch from './DashSearch';
import Loader from '../elements/Loader';

export default function(props){

    let selectedPresupuesto={name:""}
    if(props.userPack.user){
        let list={...props.userPack.user}.presupuestos
        selectedPresupuesto = props.userPack.methods.calcularPresupuesto(list.filter(c=>(c.id==props.presupuestoId))[0])
    }

    console.log(selectedPresupuesto)

    const deleteCategory=(e)=>{
        console.log(e.target.getAttribute('catid'),"catid",e.target)
        props.userPack.methods.delLine(e.target.getAttribute('catid'),"delCategory")
    }
    const newCategory=()=>{
        let newVal = {...props.userPack.loginModal}
        newVal.active=true
        newVal.presupuesto=props.presupuestoId
        newVal.type="newCategory"
        props.userPack.switchLoginModal(newVal)
        //props.userPack.methods.newMaterialInput(props.analysisId,13,1)
    }
    const newPartida=(e)=>{
        
        let newVal = {...props.userPack.loginModal}
        newVal.active=true
        newVal.category=e.target.getAttribute('catId')
        newVal.type="newPartida"
        props.userPack.switchLoginModal(newVal)
        //props.userPack.methods.newMaterialInput(props.analysisId,13,1)
    }
    return(
        <>
          <DashNavBar userPack={props.userPack}/>
          <DashSearch route="newPresp" userPack={props.userPack} newMethod={()=>{}}/>
          {props.userPack.user?
 <>
 <div className="" style={{display:"grid",gridTemplateColumns:"1fr 1fr",gridTemplateRows:"auto"}}>
 <div className="alignCenter flexStart" style={{marginTop:"10px"}}>   
   <img className="" style={{marginLeft:"2rem",width:"50px",height:"50px"}} src="https://civiltools.club/media/presupuesto.svg" alt=""/> 
   <h1 className="mainContentTitle" >
       {selectedPresupuesto.name}
       </h1>
    </div>
    <div className="alignCenter center" style={{marginTop:"10px"}}>
    <button onClick={newCategory} style={{marginLeft:"8px",width:"180px"}} className="pure-material-button-contained alignCenter justify">Agregar categoria</button>
    </div>
    </div>
<div className="alignCenter justify" style={{marginTop:"15px",marginBottom:"2.6rem"}}>
<div className="tableBox">
   <div className="table">
       <div className="mainLine tableFormat">
          <p className="nivelesTitle textAlign mainLine">Niveles: {selectedPresupuesto.niveles}</p> 
            <p className="nivelesTitle textAlign mainLine">Ud</p>
            <p className="nivelesTitle textAlign mainLine">Qty</p>
            <p className="nivelesTitle textAlign mainLine">Costo</p>
            <p className="nivelesTitle textAlign mainLine">Total</p>
       </div>

       
       {selectedPresupuesto.categories.sort((a, b) => a.nivel - b.nivel).map((c,index)=>(
           <>
                  <div className="green zsup alignCenter deleteLine" style={{height:"30px"}}>
                  <p className="mainLineText">{index+1}. {c.title}</p>  
                  <div className="nivelTag flexWrap" style={{marginLeft:"0.65rem"}}>
                      <p className="nivelText" >NIVEL {c.nivel}</p>
                  </div>
                  <button catid={c.id} onClick={newPartida} style={{marginLeft:"8px",width:"80px"}} className="deleteIcon pure-material-button-contained alignCenter justify">Agregar</button>
                  <div catid={c.id} onClick={deleteCategory} className="deleteIcon alignCenter justify buttonClass" style={{backgroundColor:"white",borderRadius:"50%",height:"30px",width:"30px",position:"relative"}}>
                   <img catid={c.id} src="https://civiltools.club/media/trash.svg" alt=""/>
                   </div>
             </div>
             {c.partidas.map((y,index)=>(
           <>
             <TableLine nivel={c.nivel} userPack={props.userPack} type="presupuesto" quantity={y.quantity} partida={y} name={y.analysis.name} dark={index%2===0?true:false}/>
             </> 
             ))}
                     <div className={c.length%2===0?"tableFormat2 zsup dargGrey":"tableFormat2 lightGrey zsup"}>
                        <div className="alignCenter justify zsupp">
                         <p className="mainLineTextBlack" style={{color:"white !important"}}>Subtotal</p>
                        </div>
                        <div className="alignCenter justify zsupp" >
                            <p className="">RD$ {props.userPack.methods.formatNumber(parseFloat(c.subTotal).toFixed(2))}</p>
                        </div>
                        
                    </div>
             </>
                ))}
                         <div className="green alignCenter deleteLine" style={{display:"grid",gridTemplateColumns:"1fr 1fr",gridTemplateRows:"auto"}}>
                             <div className="" >
                             <p className="mainLineText" style={{width:"500px !important"}}>{selectedPresupuesto.categories.length+1}. Costos indirectos</p>  
                             </div>
               

      
                        
             </div>
             <div className="tableFormat2 dargGrey zsup">
                        <div className="alignCenter justify zsupp">
                         <p className="mainLineTextBlack" style={{color:"white !important"}}>Dirección Técnica: 10%</p>
                        </div>
                        <div className="alignCenter justify zsupp" >
                            <p className="">RD$ {props.userPack.methods.formatNumber(parseFloat(selectedPresupuesto.total*0.10).toFixed(2))}</p>
                        </div>
                        
                    </div>
                    <div className="tableFormat2 lightGrey zsup">
                        <div className="alignCenter justify zsupp">
                         <p className="mainLineTextBlack" style={{color:"white !important"}}>Imprevistos: 5%</p>
                        </div>
                        <div className="alignCenter justify zsupp" >
                            <p className="">RD$ {props.userPack.methods.formatNumber(parseFloat(selectedPresupuesto.total*0.05).toFixed(2))}</p>
                        </div>
                        
                    </div>
                    <div className="tableFormat2 dargGrey zsup">
                        <div className="alignCenter justify zsupp">
                         <p className="mainLineTextBlack" style={{color:"white !important"}}>ITBIS: 18%</p>
                        </div>
                        <div className="alignCenter justify zsupp" >
                            <p className="">RD$ {props.userPack.methods.formatNumber(parseFloat(selectedPresupuesto.total*0.18).toFixed(2))}</p>
                        </div>
                        
                    </div>
             <div className="tableFormat2 lightGrey zsup">
                        <div className="alignCenter justify zsupp">
                         <p className="mainLineTextBlack" style={{color:"white !important"}}>Subtotal</p>
                        </div>
                        <div className="alignCenter justify zsupp" >
                            <p className="">RD$ {props.userPack.methods.formatNumber(parseFloat(selectedPresupuesto.total*0.33).toFixed(2))}</p>
                        </div>
                        
                    </div>

   </div>
   <div className="bottomTable alignCenter justify">
       <p className="bottomTitle textAlign" style={{padding:"20px"}}>Costo total: RD$ {props.userPack.methods.formatNumber(parseFloat(selectedPresupuesto.total*1.1).toFixed(2))}</p>
       </div>
</div>
</div>
 </>
          :
          <div className="alignCenter justify" style={{paddingTop:"100px"}}>
          <Loader />
      </div>
         
          }
          
        </>
    )
}