import React from 'react';
import {Link} from '@reach/router';

const ValueItem=(props)=>{
    return(
        <>
    <div className="alignCenter justify zsupp" style={{minWidth:"50px"}}>
        <p className="alignText">{props.value}</p>
    </div>
        </>
    )
}


export default function(props){
    let elements={}
    const delMaterialInput=(e)=>{
        props.userPack.methods.delLine(props.lineId,"delMaterialInput")
    }
    const delAnalysisInput=(e)=>{
        props.userPack.methods.delLine(props.lineId,"delAnalysisInput")
    }
    const delPartida=(e)=>{
        props.userPack.methods.delLine(props.partida.id,"delPartida")
    }
    const delMOInput=(e)=>{
        props.userPack.methods.delLine(props.lineId,"delMOInput")
    }

    const delToolInput=(e)=>{
        console.log(props.partida,"deleting")
        props.userPack.methods.delLine(props.lineId,"delToolInput")
    }

    switch (props.type) {
        case "material":
            return(
                <>
                    <div className={+props.dark?"deleteLine tableFormat zsup dargGrey":"deleteLine tableFormat lightGrey zsup"}>
                    <div className="flexStart">
                        <div onClick={delMaterialInput} className="deleteIcon alignCenter justify buttonClass" style={{backgroundColor:"white",borderRadius:"50%",height:"30px",width:"30px",position:"relative"}}>
                   <img src="https://civiltools.club/media/trash.svg" alt=""/>
                   </div>
                        <p className="mainLineTextBlack" style={{color:"black !important"}}>{props.name}</p>

                        </div>
                        <ValueItem value={props.partida.unit}/>
                        <ValueItem value={parseFloat(props.quantity).toFixed(2)}/>
                        <ValueItem value={"RD$ "+props.format(parseFloat(props.partida.cost).toFixed(0))}/>
                    
                        <ValueItem value={"RD$ "+ props.format(parseFloat(props.quantity*props.partida.cost).toFixed(2))}/>
                    </div>
                </>
                
            )
            case "trabajador":
                return(
                    <>
                                        <div className={+props.dark?"deleteLine tableFormat zsup dargGrey":"deleteLine tableFormat lightGrey zsup"}>
                                            <div className="flexStart">
                        <div onClick={delMOInput} className="deleteIcon alignCenter justify buttonClass" style={{backgroundColor:"white",borderRadius:"50%",height:"30px",width:"30px",position:"relative"}}>
                   <img src="https://civiltools.club/media/trash.svg" alt=""/>
                   </div>
                        <p className="mainLineTextBlack" style={{color:"black !important"}}>{props.name}</p>

                        </div>
                        <ValueItem value={props.partida.unit}/>
                        <ValueItem value={parseFloat(props.quantity).toFixed(2)}/>
                        <ValueItem value={"RD$ "+props.format(parseFloat(props.partida.cost).toFixed(0))}/>
                    
                        <ValueItem value={"RD$ "+ props.format(parseFloat(props.quantity*props.partida.cost).toFixed(2))}/>
                    </div>
                    </>
                )
                case "tool":
                    return(
                        <>
                                            <div className={+props.dark?"deleteLine tableFormat zsup dargGrey":"deleteLine tableFormat lightGrey zsup"}>
                                            <div className="flexStart">
                        <div className="deleteIcon alignCenter justify buttonClass" style={{backgroundColor:"white",borderRadius:"50%",height:"30px",width:"30px",position:"relative"}}>
                   <img onClick={delToolInput} src="https://civiltools.club/media/trash.svg" alt=""/>
                   </div>
                        <p className="mainLineTextBlack" style={{color:"black !important"}}>{props.name}</p>

                        </div>
                        <ValueItem value={props.partida.unit}/>
                        <ValueItem value={parseFloat(props.quantity).toFixed(2)}/>
                        <ValueItem value={"RD$ "+props.format(parseFloat(props.partida.cost).toFixed(0))}/>
                    
                        <ValueItem value={"RD$ "+ props.format(parseFloat(props.quantity*props.partida.cost).toFixed(2))}/>
                    </div>
                        </>
                    )
                    case "analysis":
                        let calcAnalysis2=props.userPack.methods.calcularAnalysis(1,props.analysis)
                        return(
                            <>
                                                <div className={+props.dark?"deleteLine tableFormat zsup dargGrey":"deleteLine tableFormat lightGrey zsup"}>
                                            <div className="flexStart">
                        <div onClick={delAnalysisInput} className="deleteIcon alignCenter justify buttonClass" style={{backgroundColor:"white",borderRadius:"50%",height:"30px",width:"30px",position:"relative"}}>
                   <img src="https://civiltools.club/media/trash.svg" alt=""/>
                   </div>
                        <p className="mainLineTextBlack" style={{color:"black !important"}}>{props.name}</p>

                        </div>
                        <ValueItem value={props.analysis.unit}/>
                        <ValueItem value={parseFloat(props.quantity).toFixed(2)}/>
                        <ValueItem value={"RD$ "+props.userPack.methods.formatNumber(parseFloat(calcAnalysis2.cost).toFixed(2))}/>
                        <ValueItem value={"RD$ "+props.userPack.methods.formatNumber(parseFloat(calcAnalysis2.cost*props.quantity).toFixed(2))}/>
                    </div>
                            </>
                        )
                        case "presupuesto":
                            const calcAnalysis=props.userPack.methods.calcularAnalysis(props.nivel,props.partida.analysis)
                            return(
                                <>
                                                    <div className={+props.dark?"deleteLine tableFormat zsup dargGrey":"deleteLine tableFormat lightGrey zsup"}>
                                                <div className="flexStart">
                        <div onClick={delPartida} className="deleteIcon alignCenter justify buttonClass" style={{backgroundColor:"white",borderRadius:"50%",height:"30px",width:"30px",position:"relative"}}>
                   <img src="https://civiltools.club/media/trash.svg" alt=""/>
                   </div>
                        <Link to={"../../analysis/"+props.partida.analysis.id} className="mainLineTextBlack" style={{color:"black !imLinkortant"}}>{props.name}</Link>

                        </div>
                            <ValueItem value={props.partida.analysis.unit}/>
                            <ValueItem value={parseFloat(props.quantity).toFixed(2)}/>
                            <ValueItem value={"RD$ "+props.userPack.methods.formatNumber(parseFloat(calcAnalysis.cost).toFixed(0))}/>
                        
                            <ValueItem value={"RD$ "+props.userPack.methods.formatNumber(parseFloat(props.quantity*calcAnalysis.cost).toFixed(2))}/>
                        </div>
                                </>
                            )
    }
 
}
