import React from 'react';
import ListingNavBar from './elements/ListingsNavBar'
import BottomNavBar from './BottomNavBar';
import {Link} from '@reach/router'
import DashNavBar from './elements/DashNavBar';

const AnalisisTable=(props)=>{
    return(
        <>

        </>
    )
}
const PresupuestoTable=(props)=>{
    return(
        <>
  
        </>
    )
}
export default function(props){
    const [help,setHelp]=React.useState(true);
    const switchHelp=()=>{
        setHelp(prev=>!prev)
    }
    const openChat=()=>{
        
        let neoModal = {...props.userPack.loginModal}
        neoModal.active=true
        neoModal.type="chat"
        console.log("pulling up",neoModal)
        props.userPack.switchLoginModal(neoModal)
        
    }
    return(
        <>
        <DashNavBar userPack={props.userPack}/>
        <div style={{marginTop:"60px"}} className="alignCenter justify">
            <div className="smallSearch alignCenter" ><svg style={{position:"absolute",left:'15px'}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M15.663 15.6628C15.8023 15.5234 15.9678 15.4127 16.1499 15.3372C16.332 15.2618 16.5272 15.2229 16.7243 15.2229C16.9214 15.2229 17.1166 15.2618 17.2987 15.3372C17.4808 15.4127 17.6462 15.5234 17.7855 15.6628L23.5605 21.4378C23.842 21.7191 24.0002 22.1006 24.0003 22.4986C24.0005 22.8965 23.8426 23.2781 23.5613 23.5596C23.28 23.841 22.8985 23.9992 22.5006 23.9994C22.1027 23.9995 21.721 23.8416 21.4395 23.5603L15.6645 17.7853C15.5251 17.646 15.4144 17.4806 15.339 17.2985C15.2635 17.1164 15.2246 16.9212 15.2246 16.7241C15.2246 16.527 15.2635 16.3318 15.339 16.1497C15.4144 15.9676 15.5251 15.8021 15.6645 15.6628H15.663Z" fill="black"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.75 18C10.8334 18 11.9062 17.7866 12.9071 17.372C13.9081 16.9574 14.8175 16.3497 15.5836 15.5836C16.3497 14.8175 16.9574 13.9081 17.372 12.9071C17.7866 11.9062 18 10.8334 18 9.75C18 8.66659 17.7866 7.5938 17.372 6.59286C16.9574 5.59193 16.3497 4.68245 15.5836 3.91637C14.8175 3.15029 13.9081 2.5426 12.9071 2.12799C11.9062 1.71339 10.8334 1.5 9.75 1.5C7.56196 1.5 5.46354 2.36919 3.91637 3.91637C2.36919 5.46354 1.5 7.56196 1.5 9.75C1.5 11.938 2.36919 14.0365 3.91637 15.5836C5.46354 17.1308 7.56196 18 9.75 18ZM19.5 9.75C19.5 12.3359 18.4728 14.8158 16.6443 16.6443C14.8158 18.4728 12.3359 19.5 9.75 19.5C7.16414 19.5 4.68419 18.4728 2.85571 16.6443C1.02723 14.8158 0 12.3359 0 9.75C0 7.16414 1.02723 4.68419 2.85571 2.85571C4.68419 1.02723 7.16414 0 9.75 0C12.3359 0 14.8158 1.02723 16.6443 2.85571C18.4728 4.68419 19.5 7.16414 19.5 9.75Z" fill="black"/>
</svg>
</div>

        </div>
        <div className="flexStart">
        <p className="mainContentTitle" style={{marginLeft:"4rem", marginTop:"1.5rem"}}>¿Qué deseas realizar?</p></div>
        <div className=" alignCenter justify">
        <div className="flexWrap dashBox" >
        <Link to="../presupuesto" className="tableBox2 alignCenter justify" style={{width:"160px",height:"160px"}}>
        <div className="innerBox">
        <img className="" src="media/presupuesto.svg" alt=""/>
<p className="subTotalText">Presupuestos</p>
</div>
</Link>

<Link to="../analysis" className="tableBox2 alignCenter justify" style={{width:"160px",height:"160px"}}>
<div className="innerBox" style={{backgroundColor:"white !important"}}>
<img className="centerX" src="media/analysis.svg" alt=""/>

<p className="subTotalText" style={{textAlign:"center"}}>Análisis</p>
</div>
</Link>

<Link to="../mo" className="tableBox2 alignCenter justify" style={{width:"160px",height:"160px"}}>
    <div className="innerBox">
    <img className="centerX" src="media/mo.svg" alt=""/>

<p className="subTotalText" style={{textAlign:"center"}}>Trabajadores</p>
</div>
</Link>

<Link to="../material" className="tableBox2 alignCenter justify" style={{width:"160px",height:"160px"}}>
    <div className="innerBox">
    <img className="" src="media/material.svg" alt=""/>

<p className="subTotalText" style={{textAlign:"center"}}>Materiales</p>
</div>
</Link>
<Link to="../tool" className="tableBox2 alignCenter justify" style={{width:"160px",height:"160px"}}>
    <div className="innerBox">
    <img className="centerX" src="media/tool.svg" alt=""/>



<p className="subTotalText" style={{textAlign:"center"}}>Herramientas</p>
</div>
</Link>

<Link to="../ferreteria" className="tableBox2 alignCenter justify" style={{width:"160px",height:"160px"}}>
    <div className="innerBox ">
    <img className="centerX" src="media/ferreteria.svg" alt=""/>



<p className="subTotalText" style={{textAlign:"center"}}>Ferreterias</p>
</div>
</Link>
<Link to="../collection" className="tableBox2 alignCenter justify" style={{width:"160px",height:"160px"}}>
    <div className="innerBox">
    <img className="" src="media/collection.svg" alt=""/>



<p className="subTotalText" style={{textAlign:"center"}}>Colecciones</p>
</div>
</Link>
<Link to="../explore" className="tableBox2 alignCenter justify" style={{width:"160px",height:"160px"}}>
    <div className="innerBox">
    <img className="" src="media/explore.svg" alt=""/>



<p className="subTotalText" style={{textAlign:"center"}}>Explorar</p>
</div>
</Link>
</div>
</div>
{help?<div className="helpSection">
<div className="innerHelpSection">
<svg style={{position:"absolute",right:"0px",bottom:"80px"}} width="204" height="60" viewBox="0 0 204 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 24C0 10.7452 16.1737 0 36.125 0H167.875C187.826 0 204 10.7452 204 24C204 37.2548 187.826 48 167.875 48H36.125C16.1737 48 0 37.2548 0 24Z" fill="#C4C4C4"/>

<path className="buttonClass" onClick={switchHelp} d="M180.176 24.9819L186.808 18.3676C186.939 18.215 187.007 18.0186 186.999 17.8178C186.992 17.617 186.908 17.4266 186.766 17.2845C186.623 17.1424 186.432 17.0591 186.231 17.0514C186.03 17.0436 185.833 17.1119 185.68 17.2426L179.048 23.8569L172.416 17.2346C172.265 17.0844 172.061 17 171.848 17C171.635 17 171.43 17.0844 171.28 17.2346C171.129 17.3849 171.044 17.5887 171.044 17.8011C171.044 18.0136 171.129 18.2174 171.28 18.3676L177.92 24.9819L171.28 31.5961C171.196 31.6677 171.128 31.7557 171.08 31.8547C171.032 31.9536 171.005 32.0615 171.001 32.1713C170.996 32.2812 171.015 32.3908 171.055 32.4932C171.095 32.5956 171.156 32.6886 171.234 32.7663C171.312 32.8441 171.406 32.9049 171.508 32.945C171.611 32.9851 171.721 33.0036 171.831 32.9994C171.941 32.9952 172.049 32.9682 172.148 32.9204C172.248 32.8725 172.336 32.8046 172.408 32.7211L179.048 26.1069L185.68 32.7211C185.833 32.8518 186.03 32.9201 186.231 32.9124C186.432 32.9046 186.623 32.8214 186.766 32.6793C186.908 32.5372 186.992 32.3467 186.999 32.1459C187.007 31.9451 186.939 31.7488 186.808 31.5961L180.176 24.9819Z" fill="black"/>

<path d="M159 60L163 48L174.5 47L159 60Z" fill="#C4C4C4"/>
</svg>
<svg className="buttonClass" onClick={openChat} style={{position:"absolute",right:"12px",bottom:"0px"}} width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="40" cy="40" r="40" fill="#93C449"/>
<path d="M48.8091 23.2712C46.3537 21.5007 43.2694 20.6155 39.5562 20.6155C36.7325 20.6155 34.3482 21.2379 32.4119 22.485C29.3383 24.4364 27.7057 27.7512 27.5076 32.425H34.6303C34.6303 31.0616 35.0288 29.7542 35.8214 28.4878C36.6162 27.2256 37.9645 26.5946 39.8663 26.5946C41.8005 26.5946 43.1337 27.1072 43.8596 28.1302C44.5897 29.1576 44.9537 30.2927 44.9537 31.5376C44.9537 32.6232 44.4109 33.6161 43.754 34.5164C43.3625 35.0753 42.8815 35.5658 42.3303 35.9681C42.3303 35.9681 38.4685 38.445 36.7734 40.4352C35.7913 41.5875 35.703 43.317 35.6146 45.7939C35.6103 45.9706 35.6771 46.3367 36.2931 46.3367H41.8306C42.382 46.3367 42.4962 45.9296 42.5026 45.7466C42.5414 44.8462 42.6405 44.3832 42.8063 43.8619C43.1165 42.8776 43.9651 42.0204 44.9149 41.2795L46.8749 39.929C48.6454 38.5484 50.0562 37.4176 50.6808 36.5302C51.7448 35.0742 52.4943 33.2779 52.4943 31.1456C52.4922 27.6672 51.2645 25.0459 48.8091 23.2712ZM39.442 50.6056C36.9888 50.5302 34.9642 52.2296 34.8866 54.8918C34.8113 57.5496 36.7346 59.3093 39.19 59.3804C41.7509 59.4558 43.7217 57.8146 43.8014 55.1524C43.8746 52.4902 42.0051 50.681 39.442 50.6056Z" fill="white"/>
</svg>
<p className="blurryLetter1" style={{position:"absolute",right:"40px",bottom:"98px",width:"140px"}}>¿Necesitas ayuda?</p>
</div>
</div>:null}

        {props.userPack.dimensions.width>760?
                <BottomNavBar userPack={props.userPack}/>:null}
        </>
    )
}