import React from 'react';
import {Link} from '@reach/router'
import MainNavBar from './elements/MainNavBar';
import ProfileButton from './elements/ProfileButton';

export default function(props){
    const openLogin=()=>{
        let newVal = {...props.userPack.loginModal}
        if(props.userPack.dimensions.width<600){
            newVal.login=true
            props.userPack.switchLoginModal(newVal)
            props.userPack.refs.routeLoginMobileRef.current.click()
        }else{
            newVal.active=true
            newVal.login=true
            props.userPack.switchLoginModal(newVal)
        }
    
      }
      const openRegister=()=>{
        let newVal = {...props.userPack.loginModal}
        newVal.active=true
        newVal.login=false
        props.userPack.switchLoginModal(newVal)
      }
      const iniciar=()=>{
        let newVal = {...props.userPack.loginModal}
        newVal.login=true
          if(props.userPack.user){
            props.userPack.refs.routeListingsRef.current.click()
            return
          }
          else{
            props.userPack.switchLoginModal(newVal)
            if(props.userPack.dimensions.width<760){
                newVal.login=false
                props.userPack.switchLoginModal(newVal)
                props.userPack.methods.neoSelected("profile")
                props.userPack.refs.routeLoginMobileRef.current.click()
                return
            }else{
                newVal.active=true
                props.userPack.switchLoginModal(newVal)
                return
            }
           
          }
      }
      const neoSelected=(e)=>{
        props.userPack.methods.neoSelected(e.target.parentElement.type)
      }
    const switchrent=(e)=>{ 
        console.log("true")
        props.userPack.switchRent("",true)
        props.userPack.methods.neoSelected("search")
    }
    const switchsale=(e)=>{ 
        props.userPack.switchRent("",false)
        props.userPack.methods.neoSelected("search")
    }
    return(
        <>

  <div className="mainBg alignCenter justify bgColor1">
    <div className="mobileNav alignCenter justify" >

        <div className="alignCenter" style={{position:"absolute",left:"15px",height:"40px",width:"40px"}}>
            <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.6667 12.9167H1.33333C1.17862 12.9167 1.03025 12.8552 0.920854 12.7458C0.811458 12.6364 0.75 12.488 0.75 12.3333C0.75 12.1786 0.811458 12.0303 0.920854 11.9209C1.03025 11.8115 1.17862 11.75 1.33333 11.75H17.6667C17.8214 11.75 17.9697 11.8115 18.0791 11.9209C18.1885 12.0303 18.25 12.1786 18.25 12.3333C18.25 12.488 18.1885 12.6364 18.0791 12.7458C17.9697 12.8552 17.8214 12.9167 17.6667 12.9167Z" fill="white"/>
                <path d="M17.6667 7.08342H1.33333C1.17862 7.08342 1.03025 7.02196 0.920854 6.91256C0.811458 6.80317 0.75 6.65479 0.75 6.50008C0.75 6.34537 0.811458 6.197 0.920854 6.0876C1.03025 5.97821 1.17862 5.91675 1.33333 5.91675H17.6667C17.8214 5.91675 17.9697 5.97821 18.0791 6.0876C18.1885 6.197 18.25 6.34537 18.25 6.50008C18.25 6.65479 18.1885 6.80317 18.0791 6.91256C17.9697 7.02196 17.8214 7.08342 17.6667 7.08342Z" fill="white"/>
                <path d="M17.6667 1.24992H1.33333C1.17862 1.24992 1.03025 1.18846 0.920854 1.07906C0.811458 0.969668 0.75 0.821295 0.75 0.666585C0.75 0.511876 0.811458 0.363503 0.920854 0.254106C1.03025 0.14471 1.17862 0.083252 1.33333 0.083252H17.6667C17.8214 0.083252 17.9697 0.14471 18.0791 0.254106C18.1885 0.363503 18.25 0.511876 18.25 0.666585C18.25 0.821295 18.1885 0.969668 18.0791 1.07906C17.9697 1.18846 17.8214 1.24992 17.6667 1.24992Z" fill="white"/>
                </svg>

            </div>

            <div className="">
            <h1 className="mainTitle buttonClass" style={{color:"white"}}>CivilTools</h1>
            </div>

                    {/* <div className="mobileSearchBar">
                        <div className="innerMobileSearch">
                            <Link to="listings" style={{color:"inherit"}} onClick={neoSelected} type="search">
                        <svg style={{marginLeft:"18px"}} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8" cy="8" r="7" stroke="#37A000" strokeWidth="2"/>
                            <line x1="12.3422" y1="12.7137" x2="18.659" y2="18.2478" stroke="#37A000" strokeWidth="2"/>
                            </svg>
                        </Link>
                            <input className="mainInput" type="text" name="" id=""/>
                    
                            
                        </div>
                    </div> */}
                </div>
                <div className="indexNavBar">
          <div className="leftWing">
              <a href="#">
              <p className="navBarTitle" style={{color:'white'}}>Contratar</p>
              </a>
              <a href="#">
              <p className="navBarTitle" style={{color:'white'}}>Explorar</p>
              </a>
          </div>
      <div className="middleLogo">
          <h1 className="mainTitle buttonClass" style={{color:"white"}}>CivilTools</h1>
  
      </div>
          
      <div className="rightWing">
          <a style={{color:"inherit"}} href="#">
          <p className="navBarTitle" style={{color:'white'}}>Ayuda</p>
          </a>
          {props.userPack.user?
         <ProfileButton methods={props.userPack.methods} user={props.userPack.user} white={true}/>
          :
          <>
                    <a onClick={openLogin} style={{color:"inherit"}} href="#">
                    <p className="navBarTitle" style={{color:'white'}}>Login</p>
                    </a>
                    <a href="#" onClick={openRegister} style={{color:"inherit"}} className="registerButton alignCenter justify">
                        <p className="registerText " >Regístrate</p>
                    </a>
                    </>
          }

      </div>  
          
      </div>        
        {/* <div className="options" style={{marginTop:"5rem"}}>
            {["Características",'Precio','Soporte','Login','Regístrate'].map((c,index)=>(

                <p key={index} className="optionText alignCenter justify buttonClass" onClick={(c==="Login"||c==="Regístrate")?openLogin:()=>{}}>{c}</p>
            ))}
        </div> */}
        <div style={{display:"grid",gridTemplateColumns:"1fr",gridTemplateRows:"auto auto auto"}}>
        <div className="alignCenter justify">
            <h1 className="mainContentTitleWhite" style={{width:"300px"}}>Maximiza tu tiempo y recursos con nuestras herramientas de costo y presupuestos</h1>
        </div>
        <div className="alignCenter justify">
            <p className="bigBody" style={{width:"300px"}}> Accede a tu trabajo online desde tu celular o computador desde cualquier parte del mundo</p>
        </div>
        <div className="alignCenter justify" style={{marginTop:"30px",marginBottom:"1.8rem"}}>
            <div onClick={iniciar} className="buttonBox1 buttonClass alignCenter justify">
            <p className="buttonText1">Iniciar</p>
            </div>
           </div>
        </div>
        </div>

  
        </>
    )
}