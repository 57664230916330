import React from 'react';
import DashNavBar from '../elements/DashNavBar';
import DashSearch from './DashSearch';
import {Link} from '@reach/router';
import Loader from '../elements/Loader';

export default function(props){
    const axiosPack = {...props.userPack.axiosPack}
    axiosPack.axios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const newPresp =()=>{
        return
    }
    const deleteAction=(e)=>{   
        const ogUser={...props.userPack.user}
        props.userPack.methods.updateUser(null)
        axiosPack.axios.post(axiosPack.baseUrl+"deleteAnalysis/",{id:e.target.id}).then(resp=>{
            props.userPack.methods.updateUser(resp.data)
            return
        }).catch(err=>{
            props.userPack.methods.updateUser(ogUser)
            
        })}

    return(
        <>
        <DashNavBar userPack={props.userPack}/>
        <DashSearch route="newAnalysis" userPack={props.userPack} newMethod={()=>{}}/>
        <div className="alignCenter flexStart" style={{marginTop:"10px"}}>   
            <img className="" style={{marginLeft:"2rem",width:"50px",height:"50px"}} src="media/analysis.svg" alt=""/> 
            <h1 className="mainContentTitle" style={{marginLeft:"1.5rem"}}>
                Mis análisis
                </h1>
             </div>
             {props.userPack.user?
             <>
             {props.userPack.user.analysiss.map(c=>(
                 <>
                  <div style={{marginLeft:"2rem"}}>
                 <Link to={"./"+c.id} className="mainContentTitle" >{c.name}</Link><img id={c.id} className="buttonClass" onClick={deleteAction} src="../media/trash.svg" alt=""/>
                 </div>
             </>
             ))}
             </>
             :
             <div className="alignCenter justify" style={{paddingTop:"100px"}}>
             <Loader />
         </div>
             }
          </>
    )
}