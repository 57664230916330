import React from 'react';

export default function(props){
    const logout=(e)=>{
        e.preventDefault()
        props.methods.logout()
    }
    let white=props.white
    return(
        <>
         <div className="profileButton" style={{border:white?" 0.1px solid white !important":"0.1px solid black !important"}}>
            <p className={white?" white":""}style={{color:white?"white !important":"inherit"}}>{props.user?props.user.first_name:""}</p>
            <svg className="sendRight" width="40" height="40" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
                <ellipse cx="25" cy="27.9252" rx="25" ry="25" fill="url(#pattern0)"/>
                <path d="M40 29C47.7672 29 54 22.4722 54 14.5C54 6.52778 47.7672 0 40 0C32.2328 0 26 6.52778 26 14.5C26 22.4722 32.2328 29 40 29Z" fill="#C71C1C" stroke="white" strokeWidth="2"/>
                <defs>
                    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image0" transform="translate(-0.289256) scale(0.00300669 0.00285714)"/>
                    </pattern>
                    <image id="image0" width="525" height="350" xlinkHref={props.user && props.user.profilePicture?props.user.profilePicture.url:"https://civiltools.club/media/prieto.png"}/>
                    </defs>
                    </svg>
                    <p className="floatingNum">2</p>
                    <div className="mainHover">
                        <p className="bulletLetter">Dashboard</p>
                        <p className="bulletLetter">Mensajes</p>
                        <p className="bulletLetter">Notificaciones</p>
                        <p className="bulletLetter">Guardados</p>
                        <p className="bulletLetter">Historial</p>
                        <p className="bulletLetter">Publica tu casa</p>
                        <p className="bulletLetter">Herramientas para construir</p>
                        <p className="bulletLetter">Cuenta</p>
                        <a href="#" onClick={logout}>
                        <p className="bulletLetter">Salir</p>
                        </a>
                    </div>
                </div>
            
        </>
    )
}