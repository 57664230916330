import React from 'react';
import {Link} from '@reach/router'
import ProfileButton from './ProfileButton';

export default function(props){

    const openLogin=()=>{
        let newVal = {...props.userPack.loginModal}
        newVal.login=true
        if(props.userPack.dimensions.width<760){
            props.userPack.switchLoginModal(newVal)
            props.userPack.methods.neoSelected("profile")
            props.userPack.refs.routeLoginMobileRef.current.click()
            return
        }else{
            newVal.active=true
            props.userPack.switchLoginModal(newVal)
        }
      }
      const openRegister=()=>{
        let newVal = {...props.userPack.loginModal}
        
        newVal.login=false
        if(props.userPack.dimensions.width<760){
            props.userPack.switchLoginModal(newVal)
            props.userPack.methods.neoSelected("profile")
            props.userPack.refs.routeLoginMobileRef.current.click()
            return
        }else{
            newVal.active=true
            props.userPack.switchLoginModal(newVal)
        }

      }
    return(
        <>
<div className="dashNavBar">
<div onClick={()=>{window.history.back()}} className="alignCenter justify buttonClass">
    <svg   width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6252 2.00708L3.0835 12.7432L13.6252 24.8798" stroke="black" strokeWidth="4"/>
    </svg> 
    </div>
    <Link to="/" className="alignCenter justify">
    <h1 className="mainTitle buttonClass">CivilTools</h1>
    </Link>
    {props.userPack.user?
    <>
        <div className=" alignCenter justify"><ProfileButton methods={props.userPack.methods} user={props.userPack.user}/></div>
        </>
    :
    <>
    <div className="alignCenter justify">
    <div onClick={openLogin} className="buttonClass" >
                    <p className="navBarTitle" style={{color:"black !important",marginRight:"1vw"}}>Login</p>
                    </div>
                    <div className="buttonClass" onClick={openRegister} style={{color:"inherit"}} className="registerButton alignCenter justify">
                        <p className="registerText " >Regístrate</p>
                    </div>
    </div>
    </>
    }

       
        </div>
        </>
    )
}
