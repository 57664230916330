import React from 'react';
import DashNavBar from '../elements/DashNavBar';
import DashSearch from './DashSearch';
export default function(props){
    return(
        <>
        <DashNavBar userPack={props.userPack}/>
        <DashSearch userPack={props.userPack} newMethod={()=>{}}/>
        <div className="alignCenter flexStart" style={{marginTop:"10px"}}>   
            <img className="" style={{marginLeft:"2rem",width:"50px",height:"50px"}} src="media/material.svg" alt=""/> 
            <h1 className="mainContentTitle" style={{marginLeft:"1.5rem"}}>
                Mis materiales
                </h1>
             </div>
        </>
    )
}