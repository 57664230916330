export default function(nivel,analysis){
        if(!nivel || nivel==0){
            nivel = 1
        }
        let nivelFactor=1
        if(nivel>1){
            nivelFactor=nivel-1
          }
        let valores = {}
        let matTotalLocal = 0
        let moTotalLocal = 0
        let toolTotalLocal = 0
        analysis.matList.forEach(element => {
            matTotalLocal = matTotalLocal + element.quantity*element.material.cost
        });
        analysis.moList.forEach(element => {
            moTotalLocal = moTotalLocal + element.quantity*element.trabajador.cost
        });
        if(nivel>1){
            moTotalLocal = moTotalLocal + moTotalLocal*0.05*nivelFactor
        }
        if(analysis.toolList == []){
            toolTotalLocal = toolTotalLocal + 0.05*moTotalLocal
        } else{
            analysis.toolList.forEach(element => {
                toolTotalLocal = toolTotalLocal + element.quantity*element.tool.cost
            });
        }
        valores.total = toolTotalLocal+matTotalLocal+moTotalLocal
        valores.cost = Math.round(valores.total/analysis.divisor)
        return(valores.cost)
    }
