import React from 'react';

export default function(props){
    const newItem=()=>{
        
        let newModal = {...props.userPack.loginModal}
        newModal.active=true
        newModal.type=props.route
        props.userPack.switchLoginModal(newModal)
        return
    }
    return(
        <>
                    <div style={{marginTop:"60px"}} className="smallSearchGrid alignCenter justify">
            <div className="smallSearch alignCenter" ><svg style={{position:"absolute",left:'15px'}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M15.663 15.6628C15.8023 15.5234 15.9678 15.4127 16.1499 15.3372C16.332 15.2618 16.5272 15.2229 16.7243 15.2229C16.9214 15.2229 17.1166 15.2618 17.2987 15.3372C17.4808 15.4127 17.6462 15.5234 17.7855 15.6628L23.5605 21.4378C23.842 21.7191 24.0002 22.1006 24.0003 22.4986C24.0005 22.8965 23.8426 23.2781 23.5613 23.5596C23.28 23.841 22.8985 23.9992 22.5006 23.9994C22.1027 23.9995 21.721 23.8416 21.4395 23.5603L15.6645 17.7853C15.5251 17.646 15.4144 17.4806 15.339 17.2985C15.2635 17.1164 15.2246 16.9212 15.2246 16.7241C15.2246 16.527 15.2635 16.3318 15.339 16.1497C15.4144 15.9676 15.5251 15.8021 15.6645 15.6628H15.663Z" fill="black"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.75 18C10.8334 18 11.9062 17.7866 12.9071 17.372C13.9081 16.9574 14.8175 16.3497 15.5836 15.5836C16.3497 14.8175 16.9574 13.9081 17.372 12.9071C17.7866 11.9062 18 10.8334 18 9.75C18 8.66659 17.7866 7.5938 17.372 6.59286C16.9574 5.59193 16.3497 4.68245 15.5836 3.91637C14.8175 3.15029 13.9081 2.5426 12.9071 2.12799C11.9062 1.71339 10.8334 1.5 9.75 1.5C7.56196 1.5 5.46354 2.36919 3.91637 3.91637C2.36919 5.46354 1.5 7.56196 1.5 9.75C1.5 11.938 2.36919 14.0365 3.91637 15.5836C5.46354 17.1308 7.56196 18 9.75 18ZM19.5 9.75C19.5 12.3359 18.4728 14.8158 16.6443 16.6443C14.8158 18.4728 12.3359 19.5 9.75 19.5C7.16414 19.5 4.68419 18.4728 2.85571 16.6443C1.02723 14.8158 0 12.3359 0 9.75C0 7.16414 1.02723 4.68419 2.85571 2.85571C4.68419 1.02723 7.16414 0 9.75 0C12.3359 0 14.8158 1.02723 16.6443 2.85571C18.4728 4.68419 19.5 7.16414 19.5 9.75Z" fill="black"/>
</svg>
</div>
<div className="buttonClass" onClick={newItem}>
<svg style={{display:"grid",gridTemplateColumns:"1fr",gridTemplateRows:"auto auto"}} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M-359 -52H134V837H-359V-52Z" fill="white"/>
<path d="M38.5098 26.3128L28.2531 16.5379C28.0768 16.3691 27.8616 16.2341 27.6213 16.1415C27.381 16.0489 27.121 16.0008 26.8579 16.0003H13.6599C13.0335 15.9938 12.4117 16.0941 11.8301 16.2953C11.2485 16.4965 10.7185 16.7947 10.2703 17.1729C9.82208 17.5511 9.46448 18.0019 9.21791 18.4995C8.97134 18.9971 8.84064 19.5319 8.83325 20.0732V44.5103C8.84064 45.0516 8.97134 45.5863 9.21791 46.084C9.46448 46.5816 9.82208 47.0324 10.2703 47.4106C10.7185 47.7888 11.2485 48.087 11.8301 48.2882C12.4117 48.4894 13.0335 48.5896 13.6599 48.5832H34.1733C34.7998 48.5896 35.4215 48.4894 36.0031 48.2882C36.5847 48.087 37.1148 47.7888 37.563 47.4106C38.0112 47.0324 38.3688 46.5816 38.6153 46.084C38.8619 45.5863 38.9926 45.0516 39 44.5103V27.4043C38.9988 27.0005 38.8241 26.6116 38.5098 26.3128ZM27.6875 20.8877L32.8535 25.7752H29.0827C28.8852 25.7649 28.6922 25.7207 28.5147 25.6452C28.3372 25.5697 28.1789 25.4645 28.049 25.3356C27.9192 25.2066 27.8203 25.0567 27.7582 24.8944C27.6961 24.7321 27.6721 24.5608 27.6875 24.3904V20.8877ZM34.1733 45.3249H13.6599C13.5287 45.3315 13.3972 45.3156 13.273 45.2782C13.1488 45.2409 13.0344 45.1827 12.9363 45.107C12.8382 45.0313 12.7584 44.9396 12.7014 44.8373C12.6444 44.7349 12.6113 44.6238 12.6041 44.5103V20.0732C12.6113 19.9597 12.6444 19.8486 12.7014 19.7462C12.7584 19.6438 12.8382 19.5522 12.9363 19.4765C13.0344 19.4008 13.1488 19.3426 13.273 19.3052C13.3972 19.2678 13.5287 19.252 13.6599 19.2586H23.9166V24.3904C23.8862 25.5905 24.4064 26.7522 25.3636 27.6221C26.3209 28.4919 27.6375 28.9993 29.0261 29.0334H35.2292V44.5103C35.2219 44.6238 35.1889 44.7349 35.1319 44.8373C35.0748 44.9396 34.995 45.0313 34.8969 45.107C34.7988 45.1827 34.6844 45.2409 34.5603 45.2782C34.4361 45.3156 34.3046 45.3315 34.1733 45.3249Z" fill="black"/>
<rect x="18" y="35" width="12" height="2" rx="1" fill="black"/>
<rect x="23" y="30" width="2" height="12" rx="1" fill="black"/>
</svg>
<p>Nuevo</p>
</div>

</div>
        </>
    )
}