import React from 'react';
import LoginElements from './LoginElements';
import RegisterElements from './RegisterElements';
import ReactSelect from 'react-select';

const InnerChat=(props)=>{
  const close=()=>{
    props.close()
  }
  return(
    <>
      <div className="flexEnd">
      <svg onClick={close} className="buttonClass" style={{position:"relative",right:"3rem",top:"2rem"}}width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.41 10.9999L20.7 2.70994C20.8638 2.51864 20.9494 2.27256 20.9397 2.02089C20.93 1.76921 20.8256 1.53047 20.6475 1.35238C20.4694 1.17428 20.2307 1.06995 19.979 1.06023C19.7274 1.05051 19.4813 1.13612 19.29 1.29994L11 9.58994L2.70997 1.28994C2.52167 1.10164 2.26627 0.99585 1.99997 0.99585C1.73367 0.99585 1.47828 1.10164 1.28997 1.28994C1.10167 1.47825 0.995881 1.73364 0.995881 1.99994C0.995881 2.26624 1.10167 2.52164 1.28997 2.70994L9.58997 10.9999L1.28997 19.2899C1.18529 19.3796 1.10027 19.4899 1.04025 19.614C0.980225 19.738 0.946494 19.8732 0.941175 20.0109C0.935855 20.1486 0.959061 20.2859 1.00934 20.4143C1.05961 20.5426 1.13587 20.6591 1.23332 20.7566C1.33078 20.854 1.44732 20.9303 1.57565 20.9806C1.70398 21.0309 1.84131 21.0541 1.97903 21.0487C2.11675 21.0434 2.25188 21.0097 2.37594 20.9497C2.50001 20.8896 2.61033 20.8046 2.69997 20.6999L11 12.4099L19.29 20.6999C19.4813 20.8638 19.7274 20.9494 19.979 20.9397C20.2307 20.9299 20.4694 20.8256 20.6475 20.6475C20.8256 20.4694 20.93 20.2307 20.9397 19.979C20.9494 19.7273 20.8638 19.4812 20.7 19.2899L12.41 10.9999Z" fill="white"/>
</svg>
      </div>
      <div className="alignCenter justify">
        <h1 className="hugeTitle">Pregunta!</h1>
      </div>
      <div className="alignCenter justify">
      <svg width="193" height="148" viewBox="0 0 193 148" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M163.83 64.4183C163.249 64.2337 162.619 64.2873 162.078 64.5673C161.536 64.8473 161.129 65.3307 160.944 65.9112C160.759 66.4918 160.813 67.1219 161.093 67.6629C161.373 68.2039 161.857 68.6115 162.437 68.7961C180.396 74.5153 188.405 83.0459 188.405 96.4689C188.405 108.408 177.51 117.674 171.021 122.139C170.714 122.35 170.463 122.632 170.289 122.962C170.115 123.292 170.024 123.659 170.024 124.031V141.593C164.507 139.501 159.578 136.105 155.659 131.694C155.394 131.398 155.056 131.175 154.68 131.048C154.303 130.92 153.9 130.891 153.509 130.963C152.199 131.216 150.862 131.574 149.506 131.937C147.163 132.567 144.746 133.219 142.453 133.219C130.652 133.219 122.39 130.683 113.944 124.477C113.453 124.116 112.838 123.964 112.235 124.056C111.632 124.148 111.091 124.475 110.729 124.966C110.368 125.457 110.217 126.072 110.308 126.674C110.4 127.277 110.728 127.818 111.219 128.18C120.533 135.024 129.581 137.813 142.453 137.813C145.352 137.813 148.073 137.082 150.701 136.375C151.519 136.15 152.332 135.929 153.137 135.741C161.725 144.809 171.439 146.872 171.862 146.954C172.196 147.024 172.541 147.017 172.872 146.935C173.203 146.853 173.511 146.698 173.774 146.481C174.038 146.266 174.251 145.995 174.398 145.688C174.544 145.38 174.62 145.044 174.619 144.703V125.23C186.484 116.773 193 106.594 193 96.4689C193 80.9374 183.732 70.7531 163.83 64.4183Z" fill="white"/>
<path d="M151.643 62.0156C151.643 26.6621 119.393 0 76.6348 0C33.6601 0 0 27.2455 0 62.0248C0 82.8758 14.7553 97.8147 27.5714 106.855V137.339C27.5738 137.722 27.6714 138.098 27.8554 138.434C28.0394 138.769 28.304 139.054 28.6254 139.261C28.9467 139.469 29.3148 139.594 29.6964 139.624C30.0779 139.654 30.461 139.589 30.8111 139.434C31.482 139.135 47.1655 132.011 60.5836 117.343C66.3966 118.422 72.9724 119.46 78.937 119.46C122.422 119.46 151.643 96.3723 151.643 62.0156ZM78.9324 114.867C72.9126 114.867 66.0703 113.714 60.17 112.588C59.779 112.516 59.3761 112.546 58.9997 112.674C58.6233 112.802 58.2858 113.024 58.0195 113.319C48.5992 123.939 37.5293 130.688 32.1667 133.595V105.656C32.1665 105.284 32.0757 104.917 31.9019 104.587C31.7282 104.257 31.4768 103.975 31.1695 103.764C19.0473 95.426 4.59524 81.4242 4.59524 62.0248C4.59524 29.818 36.238 4.59375 76.6348 4.59375C116.779 4.59375 147.048 29.2806 147.048 62.0156C147.048 93.6298 119.674 114.867 78.9324 114.867Z" fill="white"/>
<path d="M75.8213 52.8281C70.7528 52.8281 66.6309 56.9487 66.6309 62.0156C66.6309 67.0825 70.7528 71.2031 75.8213 71.2031C80.8899 71.2031 85.0118 67.0825 85.0118 62.0156C85.0118 56.9487 80.8899 52.8281 75.8213 52.8281ZM75.8213 66.6094C74.6382 66.5565 73.521 66.0494 72.7024 65.1937C71.8839 64.338 71.4271 63.1996 71.4271 62.0156C71.4271 60.8316 71.8839 59.6933 72.7024 58.8376C73.521 57.9819 74.6382 57.4748 75.8213 57.4219C77.0045 57.4748 78.1217 57.9819 78.9402 58.8376C79.7588 59.6933 80.2156 60.8316 80.2156 62.0156C80.2156 63.1996 79.7588 64.338 78.9402 65.1937C78.1217 66.0494 77.0045 66.5565 75.8213 66.6094Z" fill="white"/>
<path d="M107.988 52.8281C102.92 52.8281 98.7976 56.9487 98.7976 62.0156C98.7976 67.0825 102.92 71.2031 107.988 71.2031C113.057 71.2031 117.179 67.0825 117.179 62.0156C117.179 56.9487 113.057 52.8281 107.988 52.8281ZM107.988 66.6094C106.805 66.5565 105.688 66.0494 104.869 65.1937C104.051 64.338 103.594 63.1996 103.594 62.0156C103.594 60.8316 104.051 59.6933 104.869 58.8376C105.688 57.9819 106.805 57.4748 107.988 57.4219C109.171 57.4748 110.288 57.9819 111.107 58.8376C111.926 59.6933 112.382 60.8316 112.382 62.0156C112.382 63.1996 111.926 64.338 111.107 65.1937C110.288 66.0494 109.171 66.5565 107.988 66.6094Z" fill="white"/>
<path d="M43.6548 53.2644C38.5863 53.2644 34.4644 57.385 34.4644 62.4519C34.4644 67.5188 38.5863 71.6394 43.6548 71.6394C48.7234 71.6394 52.8453 67.5188 52.8453 62.4519C52.8453 57.385 48.7234 53.2644 43.6548 53.2644ZM43.6548 67.0457C42.4716 66.9927 41.3545 66.4856 40.5359 65.6299C39.7174 64.7742 39.2606 63.6359 39.2606 62.4519C39.2606 61.2679 39.7174 60.1296 40.5359 59.2739C41.3545 58.4182 42.4716 57.9111 43.6548 57.8582C44.838 57.9111 45.9552 58.4182 46.7737 59.2739C47.5923 60.1296 48.0491 61.2679 48.0491 62.4519C48.0491 63.6359 47.5923 64.7742 46.7737 65.6299C45.9552 66.4856 44.838 66.9927 43.6548 67.0457Z" fill="white"/>
</svg></div>
<div style={{marginTop:"5rem"}} className="alignCenter justify"><div className="buttonBox1 alignCenter justify"><p className="buttonText1">Chatea</p></div></div>
<div style={{marginTop:"20px"}} className="alignCenter justify"><div className="buttonBox1 alignCenter justify"><p className="buttonText1">Enviar correo</p></div></div>
    </>
  )
}
export default function(props){
    const nextStep=()=>{
      let newVal = {...props.userPack.loginModal}
      newVal.step=newVal.step+1
      props.userPack.switchLoginModal(newVal)
    }
    const newPresp =()=>{
      props.userPack.methods.newPresupuesto()
  }
  const newRole =()=>{
    props.userPack.methods.newRole()
}
const newCollection =()=>{
  props.userPack.methods.newCollection()
}
const newFerreteria =()=>{
  props.userPack.methods.newFerreteria()
}
const newMaterial =()=>{
  props.userPack.methods.newMaterial()
}
const newTool =()=>{
  props.userPack.methods.newTool()
}
const newAnalysis =()=>{
  props.userPack.methods.newAnalysis()
}
    const prevStep=()=>{
      let newVal = {...props.userPack.loginModal}
      newVal.step=newVal.step-1
      props.userPack.switchLoginModal(newVal)
    }
    const updateUsername=(e)=>{
      let newVal = {...props.userPack.loginModal}
      newVal.username=e.target.value
      props.userPack.switchLoginModal(newVal)
    }
    const updatePassword=(e)=>{
      let newVal = {...props.userPack.loginModal}
      newVal.password=e.target.value
      props.userPack.switchLoginModal(newVal)
    }
    const crearPresp=()=>{
      console.log("qloq")

    }
    const close=()=>{
      props.userPack.methods.closeModal()
    }
    const switchName=(e)=>{
      console.log(e.target.value)
      let newVal = {...props.userPack.loginModal}
      newVal.newName=e.target.value
      props.userPack.switchLoginModal(newVal)
    }
    
    const openLogin=()=>{
      let newVal = {...props.userPack.loginModal}
      newVal.active=true
      newVal.login=true
      props.userPack.switchLoginModal(newVal)
    }
    const openRegister=()=>{
      let newVal = {...props.userPack.loginModal}
      newVal.active=true
      newVal.login=false
      props.userPack.switchLoginModal(newVal)
    }
    let matOptions=[]
    let analOptions=[]
    let toolOptions=[]
    let moOptions=[]
    if(props.userPack.collection.materials){
      props.userPack.collection.materials.map(c=>{
        matOptions.push({label:c.name,value:c})
      })
      if(props.userPack.user&&props.userPack.collection.analysiss){
        props.userPack.collection.analysiss.concat(props.userPack.user.analysiss).map(c=>{
          matOptions.push({label:c.name,value:c})
          analOptions.push({label:c.name,value:c})
        })
      }
  
      
      props.userPack.collection.tools.map(c=>{
        toolOptions.push({label:c.name,value:c})
      })
     
      props.userPack.collection.trabajadores.map(c=>{
        moOptions.push({label:c.name,value:c})
      })
    }
    
    const [step,setSetp]=React.useState(0)
    const [material,setMaterial]=React.useState({})
    const [quantity,setQuantity]=React.useState("")

    const selectMaterial=(e)=>{
      let newVal = {...props.userPack.loginModal}
      console.log(e.value)

      newVal.material=e.value
      props.userPack.switchLoginModal(newVal)
      nextStep()
      setMaterial(e.value)
    }
    switch (props.userPack.loginModal.type) {
      case "newPresp":
        return(
          <>
                            <div className="modal modalFade alignCenter justify">
                <div className="flexCard alignCenter justify">
                <div className="flexEnd">
        <svg onClick={close} className="buttonClass" style={{position:"absolute",right:"1.3rem",top:"1rem"}}width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.41 10.9999L20.7 2.70994C20.8638 2.51864 20.9494 2.27256 20.9397 2.02089C20.93 1.76921 20.8256 1.53047 20.6475 1.35238C20.4694 1.17428 20.2307 1.06995 19.979 1.06023C19.7274 1.05051 19.4813 1.13612 19.29 1.29994L11 9.58994L2.70997 1.28994C2.52167 1.10164 2.26627 0.99585 1.99997 0.99585C1.73367 0.99585 1.47828 1.10164 1.28997 1.28994C1.10167 1.47825 0.995881 1.73364 0.995881 1.99994C0.995881 2.26624 1.10167 2.52164 1.28997 2.70994L9.58997 10.9999L1.28997 19.2899C1.18529 19.3796 1.10027 19.4899 1.04025 19.614C0.980225 19.738 0.946494 19.8732 0.941175 20.0109C0.935855 20.1486 0.959061 20.2859 1.00934 20.4143C1.05961 20.5426 1.13587 20.6591 1.23332 20.7566C1.33078 20.854 1.44732 20.9303 1.57565 20.9806C1.70398 21.0309 1.84131 21.0541 1.97903 21.0487C2.11675 21.0434 2.25188 21.0097 2.37594 20.9497C2.50001 20.8896 2.61033 20.8046 2.69997 20.6999L11 12.4099L19.29 20.6999C19.4813 20.8638 19.7274 20.9494 19.979 20.9397C20.2307 20.9299 20.4694 20.8256 20.6475 20.6475C20.8256 20.4694 20.93 20.2307 20.9397 19.979C20.9494 19.7273 20.8638 19.4812 20.7 19.2899L12.41 10.9999Z" fill="black"/>
  </svg>
      </div>
      <div className="" style={{position:"absolute",top:"3rem"}}>
        <h1 className="mainContentTitle">Crear presupuesto</h1>
        <p className="subTotalText">Nombre del proyecto</p>
        <input value={props.userPack.loginModal.newName} onChange={switchName} style={{width:"300px"}} type="text" name="" id="" placeholer="inserta" autoFocus={true}/>
      </div>
                  <div className="buttonBox2 buttonClass" onClick={newPresp} style={{position:"absolute",right:"2rem",bottom:"2rem",backgroundColor:"#88C53A"}}><p className="buttonText2">Crear</p></div>
                 
                  <div className="buttonBox3 buttonClass" onClick={close} style={{position:"absolute",left:"2rem",bottom:"2rem"}}><p className="smallButtonText">Cancelar</p></div>

                  </div>
                  </div>
          </>
        )
        case "newAnalysis":
        return(
          <>
                            <div className="modal modalFade alignCenter justify">
                <div className="flexCard alignCenter justify">
                <div className="flexEnd">
        <svg onClick={close} className="buttonClass" style={{position:"absolute",right:"1.3rem",top:"1rem"}}width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.41 10.9999L20.7 2.70994C20.8638 2.51864 20.9494 2.27256 20.9397 2.02089C20.93 1.76921 20.8256 1.53047 20.6475 1.35238C20.4694 1.17428 20.2307 1.06995 19.979 1.06023C19.7274 1.05051 19.4813 1.13612 19.29 1.29994L11 9.58994L2.70997 1.28994C2.52167 1.10164 2.26627 0.99585 1.99997 0.99585C1.73367 0.99585 1.47828 1.10164 1.28997 1.28994C1.10167 1.47825 0.995881 1.73364 0.995881 1.99994C0.995881 2.26624 1.10167 2.52164 1.28997 2.70994L9.58997 10.9999L1.28997 19.2899C1.18529 19.3796 1.10027 19.4899 1.04025 19.614C0.980225 19.738 0.946494 19.8732 0.941175 20.0109C0.935855 20.1486 0.959061 20.2859 1.00934 20.4143C1.05961 20.5426 1.13587 20.6591 1.23332 20.7566C1.33078 20.854 1.44732 20.9303 1.57565 20.9806C1.70398 21.0309 1.84131 21.0541 1.97903 21.0487C2.11675 21.0434 2.25188 21.0097 2.37594 20.9497C2.50001 20.8896 2.61033 20.8046 2.69997 20.6999L11 12.4099L19.29 20.6999C19.4813 20.8638 19.7274 20.9494 19.979 20.9397C20.2307 20.9299 20.4694 20.8256 20.6475 20.6475C20.8256 20.4694 20.93 20.2307 20.9397 19.979C20.9494 19.7273 20.8638 19.4812 20.7 19.2899L12.41 10.9999Z" fill="black"/>
  </svg>
      </div>
      <div className="" style={{position:"absolute",top:"3rem"}}>
        <h1 className="mainContentTitle">Crear análisis</h1>
        <p className="subTotalText">Nombre del análisis</p>
        <input value={props.userPack.loginModal.newName} onChange={switchName} style={{width:"300px"}} type="text" name="" id="" placeholer="inserta" autoFocus={true}/>
      </div>
                  <div className="buttonBox2 buttonClass" onClick={newAnalysis} style={{position:"absolute",right:"2rem",bottom:"2rem",backgroundColor:"#88C53A"}}><p className="buttonText2">Crear</p></div>
                 
                  <div className="buttonBox3 buttonClass" onClick={close} style={{position:"absolute",left:"2rem",bottom:"2rem"}}><p className="smallButtonText">Cancelar</p></div>

                  </div>
                  </div>
          </>
        )
        case "newCategory":

          const newCategory=()=>{
            props.userPack.methods.newCategory(props.userPack.loginModal.presupuesto,material.title,quantity)
          }

          const handleChangeCat=(e)=>{
            setMaterial({title:e.target.value})
          }
          const handleKeyNiv=(e)=>{
            if(e.key==="Enter" || e.key==="NumpadEnter"){
              newCategory()
            }
          }
          const handleChangeNiv=(e)=>{
            setQuantity(e.target.value)
          }
        return(
          <>

                            <div className="modal modalFade alignCenter justify">
                <div className="flexCard alignCenter justify">
                <div className="flexEnd">
        <svg onClick={close} className="buttonClass" style={{position:"absolute",right:"1.3rem",top:"1rem"}}width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.41 10.9999L20.7 2.70994C20.8638 2.51864 20.9494 2.27256 20.9397 2.02089C20.93 1.76921 20.8256 1.53047 20.6475 1.35238C20.4694 1.17428 20.2307 1.06995 19.979 1.06023C19.7274 1.05051 19.4813 1.13612 19.29 1.29994L11 9.58994L2.70997 1.28994C2.52167 1.10164 2.26627 0.99585 1.99997 0.99585C1.73367 0.99585 1.47828 1.10164 1.28997 1.28994C1.10167 1.47825 0.995881 1.73364 0.995881 1.99994C0.995881 2.26624 1.10167 2.52164 1.28997 2.70994L9.58997 10.9999L1.28997 19.2899C1.18529 19.3796 1.10027 19.4899 1.04025 19.614C0.980225 19.738 0.946494 19.8732 0.941175 20.0109C0.935855 20.1486 0.959061 20.2859 1.00934 20.4143C1.05961 20.5426 1.13587 20.6591 1.23332 20.7566C1.33078 20.854 1.44732 20.9303 1.57565 20.9806C1.70398 21.0309 1.84131 21.0541 1.97903 21.0487C2.11675 21.0434 2.25188 21.0097 2.37594 20.9497C2.50001 20.8896 2.61033 20.8046 2.69997 20.6999L11 12.4099L19.29 20.6999C19.4813 20.8638 19.7274 20.9494 19.979 20.9397C20.2307 20.9299 20.4694 20.8256 20.6475 20.6475C20.8256 20.4694 20.93 20.2307 20.9397 19.979C20.9494 19.7273 20.8638 19.4812 20.7 19.2899L12.41 10.9999Z" fill="black"/>
  </svg>
      </div>
      <div className="" style={{position:"absolute",top:"3rem"}}>
        <h1 className="mainContentTitle">Agregar categoría de partidas</h1>
        
        <p className="subTotalText">Título de la categoría</p>
        <input value={material.title} onChange={handleChangeCat} type="text" name="" id="" autoFocus={true}/>
        <p className="subTotalText" style={{marginTop:"10px"}}>Nivel: </p>
        <input value={quantity} onChange={handleChangeNiv} onKeyDown={handleKeyNiv} type="number" name="" id=""/>

      </div>
                  <div className="buttonBox2 buttonClass" onClick={newCategory} style={{position:"absolute",right:"2rem",bottom:"2rem",backgroundColor:"#88C53A"}}><p className="buttonText2">Crear</p></div>
                 
                  <div className="buttonBox3 buttonClass" onClick={close} style={{position:"absolute",left:"2rem",bottom:"2rem"}}><p className="smallButtonText">Cancelar</p></div>

                  </div>
                  </div>
          </>
        )
        case "newPartida":

          const newPartida=()=>{
            props.userPack.methods.newPartida(material.id,props.userPack.loginModal.category,quantity)
          }

          const handleKeyPartida=(e)=>{
            if(e.key==="Enter" || e.key==="NumpadEnter"){
              
              newPartida()
            }

          }
          const handleChangePartida=(e)=>{
            setQuantity(e.target.value)
          }
        return(
          <>

                            <div className="modal modalFade alignCenter justify">
                <div className="flexCard alignCenter justify">
                <div className="flexEnd">
        <svg onClick={close} className="buttonClass" style={{position:"absolute",right:"1.3rem",top:"1rem"}}width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.41 10.9999L20.7 2.70994C20.8638 2.51864 20.9494 2.27256 20.9397 2.02089C20.93 1.76921 20.8256 1.53047 20.6475 1.35238C20.4694 1.17428 20.2307 1.06995 19.979 1.06023C19.7274 1.05051 19.4813 1.13612 19.29 1.29994L11 9.58994L2.70997 1.28994C2.52167 1.10164 2.26627 0.99585 1.99997 0.99585C1.73367 0.99585 1.47828 1.10164 1.28997 1.28994C1.10167 1.47825 0.995881 1.73364 0.995881 1.99994C0.995881 2.26624 1.10167 2.52164 1.28997 2.70994L9.58997 10.9999L1.28997 19.2899C1.18529 19.3796 1.10027 19.4899 1.04025 19.614C0.980225 19.738 0.946494 19.8732 0.941175 20.0109C0.935855 20.1486 0.959061 20.2859 1.00934 20.4143C1.05961 20.5426 1.13587 20.6591 1.23332 20.7566C1.33078 20.854 1.44732 20.9303 1.57565 20.9806C1.70398 21.0309 1.84131 21.0541 1.97903 21.0487C2.11675 21.0434 2.25188 21.0097 2.37594 20.9497C2.50001 20.8896 2.61033 20.8046 2.69997 20.6999L11 12.4099L19.29 20.6999C19.4813 20.8638 19.7274 20.9494 19.979 20.9397C20.2307 20.9299 20.4694 20.8256 20.6475 20.6475C20.8256 20.4694 20.93 20.2307 20.9397 19.979C20.9494 19.7273 20.8638 19.4812 20.7 19.2899L12.41 10.9999Z" fill="black"/>
  </svg>
      </div>
      <div className="" style={{position:"absolute",top:"3rem"}}>
        <h1 className="mainContentTitle">Agregar partida</h1>
        {props.userPack.loginModal.step===0?
        <>
        <p className="subTotalText">Nombre del análisis</p>
        <ReactSelect autoFocus={true} options={analOptions} onChange={selectMaterial}/>
        </>
        :<> {material.divisor?
          <p className="subTotalText">{material.name} ({material.unit}) <span style={{color:"green"}}>RD$ {props.userPack.methods.calcularAnalysis(1,material).cost}</span></p>
          : <p className="subTotalText">{material.name} ({material.unit}) <span style={{color:"green"}}>RD$ {material.cost}</span> {material.ferreteria.name}</p>}
        <p className="subTotalText" style={{marginTop:"10px"}}>Cantidad: </p>
        <input value={quantity} onChange={handleChangePartida} onKeyDown={handleKeyPartida} type="number" name="" id="" autoFocus={true}/>
        </>
        }
        
   
      </div>
                  <div className="buttonBox2 buttonClass" onClick={newPartida} style={{position:"absolute",right:"2rem",bottom:"2rem",backgroundColor:"#88C53A"}}><p className="buttonText2">Crear</p></div>
                 
                  <div className="buttonBox3 buttonClass" onClick={close} style={{position:"absolute",left:"2rem",bottom:"2rem"}}><p className="smallButtonText">Cancelar</p></div>

                  </div>
                  </div>
          </>
        )

        case "newMaterialInput":

          const newMaterialInput=()=>{
            if(material.divisor){
              props.userPack.methods.newAnalysisInput(props.userPack.loginModal.analysis,material.id,quantity)
            }else{
              props.userPack.methods.newMaterialInput(props.userPack.loginModal.analysis,material.id,quantity)
            }
            
          }

          const handleKeyMat=(e)=>{
            if(e.key==="Enter" || e.key==="NumpadEnter"){
              console.log("mandao",props.userPack.loginModal.material)
              newMaterialInput()
            }

          }
          const handleChangeMat=(e)=>{
            setQuantity(e.target.value)
          }
        return(
          <>

                            <div className="modal modalFade alignCenter justify">
                <div className="flexCard alignCenter justify">
                <div className="flexEnd">
        <svg onClick={close} className="buttonClass" style={{position:"absolute",right:"1.3rem",top:"1rem"}}width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.41 10.9999L20.7 2.70994C20.8638 2.51864 20.9494 2.27256 20.9397 2.02089C20.93 1.76921 20.8256 1.53047 20.6475 1.35238C20.4694 1.17428 20.2307 1.06995 19.979 1.06023C19.7274 1.05051 19.4813 1.13612 19.29 1.29994L11 9.58994L2.70997 1.28994C2.52167 1.10164 2.26627 0.99585 1.99997 0.99585C1.73367 0.99585 1.47828 1.10164 1.28997 1.28994C1.10167 1.47825 0.995881 1.73364 0.995881 1.99994C0.995881 2.26624 1.10167 2.52164 1.28997 2.70994L9.58997 10.9999L1.28997 19.2899C1.18529 19.3796 1.10027 19.4899 1.04025 19.614C0.980225 19.738 0.946494 19.8732 0.941175 20.0109C0.935855 20.1486 0.959061 20.2859 1.00934 20.4143C1.05961 20.5426 1.13587 20.6591 1.23332 20.7566C1.33078 20.854 1.44732 20.9303 1.57565 20.9806C1.70398 21.0309 1.84131 21.0541 1.97903 21.0487C2.11675 21.0434 2.25188 21.0097 2.37594 20.9497C2.50001 20.8896 2.61033 20.8046 2.69997 20.6999L11 12.4099L19.29 20.6999C19.4813 20.8638 19.7274 20.9494 19.979 20.9397C20.2307 20.9299 20.4694 20.8256 20.6475 20.6475C20.8256 20.4694 20.93 20.2307 20.9397 19.979C20.9494 19.7273 20.8638 19.4812 20.7 19.2899L12.41 10.9999Z" fill="black"/>
  </svg>
      </div>
      <div className="" style={{position:"absolute",top:"3rem"}}>
        <h1 className="mainContentTitle">Agregar material ó análisis</h1>
        {props.userPack.loginModal.step===0?
        <>
        <p className="subTotalText">Nombre del material/análisis</p>
        <ReactSelect autoFocus={true} options={matOptions} onChange={selectMaterial}/>
        </>
        :<>
        {material.divisor?
        <p className="subTotalText">{material.name} ({material.unit}) <span style={{color:"green"}}>RD$ {props.userPack.methods.calcularAnalysis(1,material).cost}</span></p>
        : <p className="subTotalText">{material.name} ({material.unit}) <span style={{color:"green"}}>RD$ {material.cost}</span> {material.ferreteria.name}</p>}
       
        <p className="subTotalText" style={{marginTop:"10px"}}>Cantidad: </p>
        <input value={quantity} onChange={handleChangeMat} onKeyDown={handleKeyMat} type="number" name="" id="" autoFocus={true}/>
        </>
        }
        
   
      </div>
                  <div className="buttonBox2 buttonClass" onClick={newMaterialInput} style={{position:"absolute",right:"2rem",bottom:"2rem",backgroundColor:"#88C53A"}}><p className="buttonText2">Crear</p></div>
                 
                  <div className="buttonBox3 buttonClass" onClick={close} style={{position:"absolute",left:"2rem",bottom:"2rem"}}><p className="smallButtonText">Cancelar</p></div>

                  </div>
                  </div>
          </>
        )
        case "newToolInput":

          const newToolInput=()=>{
            props.userPack.methods.newToolInput(props.userPack.loginModal.analysis,material.id,quantity)
          }

          const handleKeyTool=(e)=>{
            if(e.key==="Enter" || e.key==="NumpadEnter"){
              console.log("mandao",props.userPack.loginModal.material)
              newToolInput()
            }

          }
          const handleChangeTool=(e)=>{
            setQuantity(e.target.value)
          }
        return(
          <>

                            <div className="modal modalFade alignCenter justify">
                <div className="flexCard alignCenter justify">
                <div className="flexEnd">
        <svg onClick={close} className="buttonClass" style={{position:"absolute",right:"1.3rem",top:"1rem"}}width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.41 10.9999L20.7 2.70994C20.8638 2.51864 20.9494 2.27256 20.9397 2.02089C20.93 1.76921 20.8256 1.53047 20.6475 1.35238C20.4694 1.17428 20.2307 1.06995 19.979 1.06023C19.7274 1.05051 19.4813 1.13612 19.29 1.29994L11 9.58994L2.70997 1.28994C2.52167 1.10164 2.26627 0.99585 1.99997 0.99585C1.73367 0.99585 1.47828 1.10164 1.28997 1.28994C1.10167 1.47825 0.995881 1.73364 0.995881 1.99994C0.995881 2.26624 1.10167 2.52164 1.28997 2.70994L9.58997 10.9999L1.28997 19.2899C1.18529 19.3796 1.10027 19.4899 1.04025 19.614C0.980225 19.738 0.946494 19.8732 0.941175 20.0109C0.935855 20.1486 0.959061 20.2859 1.00934 20.4143C1.05961 20.5426 1.13587 20.6591 1.23332 20.7566C1.33078 20.854 1.44732 20.9303 1.57565 20.9806C1.70398 21.0309 1.84131 21.0541 1.97903 21.0487C2.11675 21.0434 2.25188 21.0097 2.37594 20.9497C2.50001 20.8896 2.61033 20.8046 2.69997 20.6999L11 12.4099L19.29 20.6999C19.4813 20.8638 19.7274 20.9494 19.979 20.9397C20.2307 20.9299 20.4694 20.8256 20.6475 20.6475C20.8256 20.4694 20.93 20.2307 20.9397 19.979C20.9494 19.7273 20.8638 19.4812 20.7 19.2899L12.41 10.9999Z" fill="black"/>
  </svg>
      </div>
      <div className="" style={{position:"absolute",top:"3rem"}}>
        <h1 className="mainContentTitle">Agregar material ó análisis</h1>
        {props.userPack.loginModal.step===0?
        <>
        <p className="subTotalText">Nombre de la herramienta o equipo</p>
        <ReactSelect autoFocus={true} options={toolOptions} onChange={selectMaterial}/>
        </>
        :<><p className="subTotalText">{material.name} ({material.unit}) <span style={{color:"green"}}>RD$ {material.cost}</span> {material.ferreteria.name}</p>
        <p className="subTotalText" style={{marginTop:"10px"}}>Cantidad: </p>
        <input value={quantity} onChange={handleChangeTool} onKeyDown={handleKeyTool} type="number" name="" id="" autoFocus={true}/>
        </>
        }
        
   
      </div>
                  <div className="buttonBox2 buttonClass" onClick={newToolInput} style={{position:"absolute",right:"2rem",bottom:"2rem",backgroundColor:"#88C53A"}}><p className="buttonText2">Crear</p></div>
                 
                  <div className="buttonBox3 buttonClass" onClick={close} style={{position:"absolute",left:"2rem",bottom:"2rem"}}><p className="smallButtonText">Cancelar</p></div>

                  </div>
                  </div>
          </>
        )
        case "newMOInput":

          const newMOInput=()=>{
            props.userPack.methods.newMOInput(props.userPack.loginModal.analysis,material.id,quantity)
          }

          const handleKeyMo=(e)=>{
            if(e.key==="Enter" || e.key==="NumpadEnter"){
              console.log("mandao",props.userPack.loginModal.material)
              newMOInput()
            }

          }
          const handleChangeMo=(e)=>{
            setQuantity(e.target.value)
          }
        return(
          <>

                            <div className="modal modalFade alignCenter justify">
                <div className="flexCard alignCenter justify">
                <div className="flexEnd">
        <svg onClick={close} className="buttonClass" style={{position:"absolute",right:"1.3rem",top:"1rem"}}width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.41 10.9999L20.7 2.70994C20.8638 2.51864 20.9494 2.27256 20.9397 2.02089C20.93 1.76921 20.8256 1.53047 20.6475 1.35238C20.4694 1.17428 20.2307 1.06995 19.979 1.06023C19.7274 1.05051 19.4813 1.13612 19.29 1.29994L11 9.58994L2.70997 1.28994C2.52167 1.10164 2.26627 0.99585 1.99997 0.99585C1.73367 0.99585 1.47828 1.10164 1.28997 1.28994C1.10167 1.47825 0.995881 1.73364 0.995881 1.99994C0.995881 2.26624 1.10167 2.52164 1.28997 2.70994L9.58997 10.9999L1.28997 19.2899C1.18529 19.3796 1.10027 19.4899 1.04025 19.614C0.980225 19.738 0.946494 19.8732 0.941175 20.0109C0.935855 20.1486 0.959061 20.2859 1.00934 20.4143C1.05961 20.5426 1.13587 20.6591 1.23332 20.7566C1.33078 20.854 1.44732 20.9303 1.57565 20.9806C1.70398 21.0309 1.84131 21.0541 1.97903 21.0487C2.11675 21.0434 2.25188 21.0097 2.37594 20.9497C2.50001 20.8896 2.61033 20.8046 2.69997 20.6999L11 12.4099L19.29 20.6999C19.4813 20.8638 19.7274 20.9494 19.979 20.9397C20.2307 20.9299 20.4694 20.8256 20.6475 20.6475C20.8256 20.4694 20.93 20.2307 20.9397 19.979C20.9494 19.7273 20.8638 19.4812 20.7 19.2899L12.41 10.9999Z" fill="black"/>
  </svg>
      </div>
      <div className="" style={{position:"absolute",top:"3rem"}}>
        <h1 className="mainContentTitle">Agregar trabajador</h1>
        {props.userPack.loginModal.step===0?
        <>
        <p className="subTotalText">Nombre del trabajador</p>
        <ReactSelect autoFocus={true} options={moOptions} onChange={selectMaterial}/>
        </>
        :<><p className="subTotalText">{material.name} <span style={{color:"green"}}>RD$ {material.cost}</span> / {material.unit}</p>
        <p className="subTotalText" style={{marginTop:"10px"}}>Cantidad: </p>
        <input value={quantity} onChange={handleChangeMo} onKeyDown={handleKeyMo} type="number" name="" id="" autoFocus={true}/>
        </>
        }
        
   
      </div>
                  <div className="buttonBox2 buttonClass" onClick={newMOInput} style={{position:"absolute",right:"2rem",bottom:"2rem",backgroundColor:"#88C53A"}}><p className="buttonText2">Crear</p></div>
                 
                  <div className="buttonBox3 buttonClass" onClick={close} style={{position:"absolute",left:"2rem",bottom:"2rem"}}><p className="smallButtonText">Cancelar</p></div>

                  </div>
                  </div>
          </>
        )
      case "":
        return(
          <>
                  <div className="modal modalFade alignCenter justify">
                <div className="modalLoginCard alignCenter">
                                    
                <div className="upperCard alignCenter justify">
                      <a href="#" className="closeIcon" onClick={close}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.5 1.6375L12.3625 0.5L7 5.8625L1.6375 0.5L0.5 1.6375L5.8625 7L0.5 12.3625L1.6375 13.5L7 8.1375L12.3625 13.5L13.5 12.3625L8.1375 7L13.5 1.6375Z" fill="#1F1F1F"/>
                        </svg>
                      </a>
                      <p className="secondTitle"> {props.userPack.loginModal.login?
                    "Inicia sesión"
                      :"Regístrate"
                      }</p>
                    </div>
                  <div className="centerCard2 alignCenter justify" style={{paddingLeft:""}}>
          

                    {/* <div className="closeIcon" style={{marginTop:"12px"}}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4965 18.6605L10.5055 19.6772C10.0859 20.1077 9.40743 20.1077 8.9923 19.6772L0.314697 10.7786C-0.104899 10.3481 -0.104899 9.65199 0.314697 9.22607L8.9923 0.322929C9.4119 -0.107572 10.0849 -0.107675 10.5 0.322827L11.4786 1.5001C10.9832 0.991744 9.93299 2.07409 9.5 2.50001L2.5 9.26728L18.5 9C19.0937 9 20 8.65817 20 9.26728V10.7328C20 11.3419 19.5937 11 19 11L2.5 10.7786L11 18.5C11.4375 18.9259 11.925 18.2254 11.4965 18.6605Z" fill="#616161"/>
        </svg>
                    </div> */}
        
         
                
                <div className="">
                    {props.userPack.loginModal.login?
                    <LoginElements userPack={props.userPack}/>
                      :
                      <RegisterElements userPack={props.userPack}/>}
                </div>
                </div>
                </div>
                </div>
    </>
        )
          case "chat":
            return(
            <>
            {props.userPack.dimensions.width<600? <div className="modal chatBg">
          <InnerChat close={close}/>
</div>:null}
      </>)
      }
  
    }
    