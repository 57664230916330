import React, { Component } from 'react';
import './App.css';
import { Router, Link } from "@reach/router";
import MainView from "./MainView"
import Listings from "./Listings"
import LoginMobile from "./LoginMobile"
import LoginModal from "./elements/LoginModal"
import moment from 'moment';
import BottomNavBar from './BottomNavBar'
import Profile from './Profile';
import Chat from './elements/Chat';
import DashBoard from './DashBoard';
import Analysis from './ingeElements/Analysis'
import Presupuesto from './ingeElements/Presupuesto'
import Presupuestos from './ingeElements/Presupuestos'
import Analysiss from './ingeElements/Analysiss'
import Material from './ingeElements/Material'
import Materials from './ingeElements/Materials'
import Tool from './ingeElements/Tool'
import Tools from './ingeElements/Tools'
import MO from './ingeElements/MO'
import MOs from './ingeElements/MOs'
import Explore from './ingeElements/Explore'
import GeneralModal from './elements/GeneralModal';
import subAnalysis from './ingeElements/SubAnalysis'
import Collection from './ingeElements/Collections';
import Ferreteria from './ingeElements/Ferreterias';
import Ferreterias from './ingeElements/Ferreterias';
import Collections from './ingeElements/Collections';

const getUrl = window.location;
let host = getUrl.host
if(getUrl.host.includes(":")){
  host = getUrl.host.substring(0, getUrl.host.length - 5);
}
let baseUrl="https://costosback.herokuapp.com/"
// let baseUrl = getUrl.protocol+ "//" + host +":8181/";
let baseWss = getUrl.protocol+ "//" + host +":8282/";


const axios = require('axios');
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = false;

const currencySymbols = [
  {
    value: 'USD',
    label: 'US$',
  },
  {
    value: 'DOP',
    label: 'RD$',
  },
  {
    value: 'EUR',
    label: '€',
  },

];

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
function validateEmail(email) {
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
}
const defaultModal=
  {active:false,type:"",firstName:'',step:0,quantity:'',newName:'',social:false,lastName:'',phoneNumber:'',login:null,
  username:'',password:'',innerLoading:false,innerError:null}
class App extends Component {
  state = { loginModal:defaultModal,gerenalModal:{active:false,type:""},
  selected:null,currency:{symbols:currencySymbols,
    rates:{"USD":1,"AED":3.672058,"ARS":60.073152,"AUD":1.462619,"BGN":1.772324,"BRL":4.175311,"BSD":1,"CAD":1.313949,"CHF":0.970542,"CLP":775.032232,"CNY":6.937035,"COP":3356.26087,"CZK":22.774105,"DKK":6.769282,"DOP":53.200551,"EGP":15.731404,"EUR":0.906153,"FJD":2.173377,"GBP":0.763843,"GTQ":7.686349,"HKD":7.772394,"HRK":6.743727,"HUF":304.680441,"IDR":13481.299912,"ILS":3.454776,"INR":71.322065,"ISK":124.593825,"JPY":109.43859,"KRW":1168.893381,"KZT":378.401961,"MXN":18.789109,"MYR":4.06712,"NOK":9.020184,"NZD":1.513791,"PAB":1,"PEN":3.322153,"PHP":50.850544,"PKR":154.697395,"PLN":3.850458,"PYG":6432.833333,"RON":4.330082,"RUB":61.891661,"SAR":3.750937,"SEK":9.552551,"SGD":1.351475,"THB":30.561937,"TRY":5.940419,"TWD":30.040088,"UAH":24.306582,"UYU":37.255792,"ZAR":14.392269},selected:"DOP"},
          axiosPack:{axios,baseUrl,baseWss},rawResults:[],
            user:null,rent:false,dimensions:{isMobile:null,width:null,height:null,scrollValue:0},
            collection:[]
   }  
   neoSelected=(element)=>{
     this.setState({selected:element})
   }
   processKey=(e)=>{
     if(e.code==="Escape" && this.state.loginModal.active){
      this.closeModal()
        return
     }
     if((e.code==="Enter" || e.code==="NumpadEnter")&& ((this.state.loginModal.type&&this.state.loginModal.type==="") || window.location.href.includes("loginMobile"))){
       if(this.state.loginModal.login){
        this.login(this.state.loginModal.username,this.state.loginModal.password)
        return
       }else{
        this.handleRegister()
        return
      }
      }if(this.state.loginModal.type && e.code==="Enter" ){
        switch (this.state.loginModal.type) {
          case "newPresp":
            this.newPresupuesto()
            return
            case "newAnalysis":
              this.newAnalysis()
              return
              case "newTool":
                this.newTool()
                return
                case "newMaterial":
                  this.newMaterial()
                  return
                  case "newCollection":
                    this.newCollection()
                    return
                    case "newFerreteria":
                      this.newFerreteria()
                      return
                      case "newRole":
                        this.newRole()
                        return
          default:
            break;
        }
      }
      
   
   }
   handleScroll=()=>{
    let newDimensions={...this.state.dimensions}
    newDimensions.scrollValue=window.scrollY
    this.setState({dimensions:newDimensions})
  }
  closeModal=()=>{
    this.setState({loginModal:defaultModal})
  }

  calcularAnalysis(nivel,realAnal){
    const that = this
    let matTotal = 0
    let moTotal = 0
    let toolTotal = 0
    let nivelFactor=1
    if(!nivel || nivel==0){
        nivel = 1
    }
    if(nivel>1){
      nivelFactor=nivel-1
    }
    if(realAnal.analysis){
        realAnal=realAnal.analysis
    }
    realAnal.matList.forEach(element => {
        matTotal= matTotal+ element.quantity*element.material.cost
    });
    if(realAnal.analysisList){
    realAnal.analysisList.forEach(element => {
        if(element.analysisBase){
            if(element.analysisBase.cost){
                matTotal= matTotal+element.quantity*element.analysisBase.cost
            } else{
                matTotal= matTotal+element.quantity*subAnalysis(nivel,element.analysisBase)
            }
        } else{
            matTotal= matTotal+element.quantity*subAnalysis(nivel,element.material.analysis)
        } 
        
    });}
    realAnal.moList.forEach(element => {
        moTotal = moTotal + element.quantity*element.trabajador.cost
    });
    if(nivel>1){
        moTotal = moTotal + moTotal*0.05*(nivelFactor)
    }
    if(realAnal.toolList.length==0){
        toolTotal = toolTotal+ 0.05*moTotal
    } else{
        realAnal.toolList.forEach(element => {
            toolTotal = toolTotal + element.quantity*element.tool.cost
        });
    }
    const valores ={}
    valores.toolTotal = toolTotal
    valores.matTotal = matTotal
    valores.moTotal = moTotal
    valores.total = toolTotal+matTotal+moTotal
    valores.cost = Math.round(valores.total/realAnal.divisor)
    
    return(valores)

  }
  calcularPresupuesto=(presupuesto)=>{
    let presupuestoReal = {...presupuesto}
    let total = 0
    let niveles = 1
    presupuestoReal.categories.forEach((category,index) => {
        if(category.nivel>niveles){
          niveles = category.nivel
        }
        let subTotal= 0
        category.partidas.forEach((partida,index2) => {
            let cost = this.calcularAnalysis(category.nivel,partida.analysis).cost
            subTotal = subTotal+cost*partida.quantity
            presupuestoReal.categories[index].partidas[index2].cost=cost
            presupuestoReal.categories[index].partidas[index2].total=subTotal
            console.log(cost,subTotal,"subTotal")
        });
        total = total + subTotal
        console.log(subTotal)
        presupuestoReal.categories[index].subTotal=subTotal    
    });
    presupuestoReal.total = total
    presupuestoReal.niveles = niveles
    return(presupuestoReal)
  }
  delLine =(id,path)=>{
    const that = this
    const neoAxios = axios
    this.setState({loginModal:defaultModal})
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+path+"/",{id}).then(resp=>{
    this.closeModal()
      console.log("resp")
        that.updateUser(resp.data)
        return
    }).catch(err=>{
      console.log("bobo")
        that.updateUser(ogUser)
        
    })
  }
  newPresupuesto =()=>{
    const that = this
    const neoAxios = axios
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+"newPresupuesto/",{name:this.state.loginModal.newName}).then(resp=>{
    that.closeModal()
    that.setState({loginModal:defaultModal})
      console.log("resp")
        that.updateUser(resp.data)
        return
    }).catch(err=>{
      console.log("bobo")
        that.updateUser(ogUser)
        
    })
  }
  newAnalysis =()=>{
    const that = this
    const neoAxios = axios
    
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+"newAnalysis/",{name:this.state.loginModal.newName}).then(resp=>{
      that.closeModal()
      that.setState({loginModal:defaultModal})
      that.updateUser(resp.data)
        return
    }).catch(err=>{
        that.updateUser(ogUser)
        
    })
  }
  newMaterial =()=>{
    const that = this
    const neoAxios = axios
    this.setState({loginModal:defaultModal})
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+"newMaterial/",{}).then(resp=>{
    this.closeModal()
      that.updateUser(resp.data)
        return
    }).catch(err=>{
        that.updateUser(ogUser)
        
    })
  }
  newCategory =(id,title,nivel)=>{
    const that = this
    const neoAxios = axios
    this.setState({loginModal:defaultModal})
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+"newCategory/",{id,title,nivel}).then(resp=>{
    this.closeModal()
      that.updateUser(resp.data)
        return
    }).catch(err=>{
        that.updateUser(ogUser)
        
    })
  }
  newPartida =(id,categoryId,quantity)=>{
    const that = this
    const neoAxios = axios
    this.setState({loginModal:defaultModal})
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+"newPartida/",{id,categoryId,quantity}).then(resp=>{
    this.closeModal()
      that.updateUser(resp.data)
        return
    }).catch(err=>{
        that.updateUser(ogUser)
        
    })
  }
  newMaterialInput =(id,materialId,quantity)=>{
    const that = this
    this.setState({loginModal:defaultModal})
    const neoAxios = axios
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+"newMaterialInput/",{id,materialId,quantity}).then(resp=>{
    this.closeModal()
      that.updateUser(resp.data)
        return
    }).catch(err=>{
        that.updateUser(ogUser)
        
    })
  }
  newAnalysisInput =(analysisOwnerId,analysisBaseId,quantity)=>{
    const that = this
    this.setState({loginModal:defaultModal})
    const neoAxios = axios
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+"newAnalysisInput/",{analysisBaseId,analysisOwnerId,quantity}).then(resp=>{
    this.closeModal()
      that.updateUser(resp.data)
        return
    }).catch(err=>{
        that.updateUser(ogUser)
        
    })
  }
  newMOInput =(id,trabajadorId,quantity)=>{
    const that = this
    const neoAxios = axios
    this.setState({loginModal:defaultModal})
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+"newMOInput/",{id,trabajadorId,quantity}).then(resp=>{
    this.closeModal()
      that.updateUser(resp.data)
        return
    }).catch(err=>{
        that.updateUser(ogUser)
        
    })
  }
  newToolInput =(id,toolId,quantity)=>{
    const that = this
    const neoAxios = axios
    this.setState({loginModal:defaultModal})
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+"newToolInput/",{id,toolId,quantity}).then(resp=>{
    this.closeModal()
      that.updateUser(resp.data)
        return
    }).catch(err=>{
        that.updateUser(ogUser)
        
    })
  }
  newTool =()=>{
    const that = this
    const neoAxios = axios
    this.setState({loginModal:defaultModal})
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+"newTool/",{}).then(resp=>{
    this.closeModal()
      that.updateUser(resp.data)
        return
    }).catch(err=>{
        that.updateUser(ogUser)
        
    })
  }
  newTrabajador =()=>{
    const that = this
    const neoAxios = axios
    this.setState({loginModal:defaultModal})
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+"newTrabajador/",{}).then(resp=>{
    this.closeModal()
      that.updateUser(resp.data)
        return
    }).catch(err=>{
        that.updateUser(ogUser)
        
    })
  }
  newFerreteria =()=>{
    const that = this
    const neoAxios = axios
    this.setState({loginModal:defaultModal})
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+"newFerreteria/",{}).then(resp=>{
    this.closeModal()
      that.updateUser(resp.data)
        return
    }).catch(err=>{
        that.updateUser(ogUser)
        
    })
  }
  newCollection =()=>{
    const that = this
    const neoAxios = axios
    this.setState({loginModal:defaultModal})
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+"newCollection/",{}).then(resp=>{
    this.closeModal()
      that.updateUser(resp.data)
        return
    }).catch(err=>{
        that.updateUser(ogUser)
        
    })
  }
  newRole =()=>{
    const that = this
    const neoAxios = axios
    this.setState({loginModal:defaultModal})
    neoAxios.defaults.headers.post['Authorization']="JWT "+window.localStorage.getItem('token')
    const ogUser={...this.state.user}
    this.updateUser(null)
    
    neoAxios.post(baseUrl+"newPresupuesto/",{}).then(resp=>{
    this.closeModal()
      that.updateUser(resp.data)
        return
    }).catch(err=>{
        that.updateUser(ogUser)
        
    })
  }
  updateDimensions=()=>{
    const w = window;
    const d = document;
    const documentElement = d.documentElement;
    const body = d.getElementsByTagName('body')[0];
    const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
    const height = w.innerHeight || documentElement.clientHeight || body.clientHeight;
    if(height-this.state.dimensions.height<60 &&height-this.state.dimensions.height>0 || width-this.state.dimensions.width===0){
      return
    }
    if (width < 763){
      const newDimensions={width:width, height:height, isMobile:true}
      this.setState({dimensions:newDimensions})
      return
    }
    const newDimensions={width:width, height:height, isMobile:false}
    this.setState({dimensions:newDimensions})
  }
  updateVals=()=>{
    const val = this.state.dimensions.scrollValue
   
    let indexBar=document.querySelector('#root > div > div > div > div.indexNavBar')
    let movNav=document.querySelector('#root > div > div > div > div.mobileNav')
    let dashNav=document.querySelector('#root > div > div > div.dashNavBar')

      if(val<22 ){
        try {
          movNav.style.position="absolute"
          movNav.style.backgroundColor="inherit"
        }catch (err){}
        try {indexBar.style.backgroundColor="inherit"}catch (err){}
        try {
          dashNav.style.backgroundColor="white"
          dashNav.style.position="fixed"
        }catch (err){}
      }
      if(val>30 &&val>0){
        try {
          movNav.style.backgroundColor="black"
          movNav.style.position="fixed"
        }catch (err){}
        try {indexBar.style.backgroundColor="black"}catch (err){}
        try {dashNav.style.backgroundColor="white"
        dashNav.style.position="fixed"
        }catch (err){}
        
      }
      // if(val>30){
      //   const alertEl=document.querySelector('#root > div > div.mobileNav > div.alertBg')
      //   alertEl.style.top=-70+'px'
      //   const mobileSearchBar=document.querySelector('#root > div.mobileNav > div.mobileSearchBar')
      //   mobileSearchBar.style.top=0+'px'
      // }

    
  }
  constructor(props){
    super(props)
    this.routeProfileRef= React.createRef();
    this.routeChatRef= React.createRef();
    this.routeHomeRef= React.createRef();
    this.routeListingsRef= React.createRef();
    this.routeSavedRef= React.createRef();
    this.routeLoginMobileRef= React.createRef();


  }
  componentDidUpdate(props){
    this.updateVals();
  }
  
  handleRegister = (data=false)=>{
    let neoModal = {...this.state.loginModal}
    neoModal.innerLoading=true
    this.setState({loginModal:neoModal})
    const that = this
    async function register (){
      let sendingData
      if(data){
        sendingData=data
      }else{
        sendingData=data={}
      }
      let myUsername = that.state.loginModal.username
      let myPassword = that.state.loginModal.password
      sendingData.username=myUsername
      sendingData.password=myPassword

      sendingData.phoneNumber=that.state.loginModal.phoneNumber
      let social = that.state.loginModal.social

      const isEmail = await validateEmail(myUsername)
      if(!isEmail){
        neoModal.innerLoading=false
        that.setState({loginModal:neoModal})
        return
      }
      if(myPassword.length<8){
        neoModal.innerLoading=false
        that.setState({loginModal:neoModal})
        return
      }
      if(social){
        axios.post(baseUrl+"custom-auth/",sendingData)
        .then(res=>{
          console.log(res.data, "logging you in 1 ...")
          that.systemLogin(res.data.user,res.data.token)
          neoModal.innerLoading=false
          neoModal.social=false
          neoModal.active=false
          that.setState({loginModal:neoModal})
          if(window.location.href.includes('/loginMobile')){
            // console.log("ta incluyendo")
            try{that.routeProfileRef.current.click()}catch (err){console.log(err)}
            return
        }else{
          // console.log("no ta incluyendo",window.location.href)
        }}
          ).catch(err=>{
            neoModal.innerLoading=false
            neoModal.innerError="error post info fetching"
            neoModal.social=false
            that.setState({loginModal:neoModal})
            console.log("hay bosbo")
            return
              })
      }else{
        sendingData.first_name=that.state.loginModal.firstName
        sendingData.last_name=that.state.loginModal.lastName
        await axios.post(baseUrl+"api/existing/",sendingData)
        .then(res=>{
          if(res.data.message==="existe"){
            that.login(sendingData.username,sendingData.password)
            return
                  }
                  else{
                    that.login(sendingData.username,sendingData.password)
                    return
                  }
          },err=>{
            
          neoModal.innerLoading=false
          neoModal.innerError="Este correo ya existe, intente iniciar sesión"
          that.setState({loginModal:neoModal})
        })
        
      }


      //window.location.href = ("https://carly.do")
    }
    register()
  }

  login = (username,password) =>{
    
    let neoModal = {...this.state.loginModal}
    neoModal.innerLoading=true
    neoModal.innerError=null
    this.setState({loginModal:neoModal})
    axios.post(baseUrl+"token-auth/",{username,password})
    .then(res=>{
      console.log("success")
        this.systemLogin(res.data.user,res.data.token)
        let neoModal = {...this.state.loginModal}
        neoModal.innerLoading=false
        neoModal.active=false
        this.setState({loginModal:neoModal})
        if(window.location.href.includes('/loginMobile')){
            // console.log("ta incluyendo")
            try{this.routeListingsRef.current.click()}catch (err){console.log(err)}
        }else{
          // console.log("no ta incluyendo",window.location.href)
        }
        return
    },
    error=>{
      
      console.log("hay bobooo")
      let neoModal = {...this.state.loginModal}
      neoModal.innerLoading=false
      neoModal.innerError="Usuario y/o contraseña incorrecta"
      this.setState({loginModal:neoModal})
        return false
})}
systemLogin=(user,token)=>{
    window.localStorage.setItem('token',token);
    window.localStorage.setItem('userId',user.id);
    // let notifications = [].concat(user.activeLeasing).concat(user.activeRenting)
    // //console.log("estas iban a ser las notfs", notifications)
    // const notificationClient = this.openNotifications(user.id)
    const newSwitch = {...this.state.loginModal}
    newSwitch.active=false
    console.log(user)
    this.setState({user,fetchedUser:true,logged:true,loginModal:newSwitch});
    try{this.routeListingsRef.current.click()}catch (err){console.log(err)}
  //   if(window.location.href.includes('/loginMobile')){
  //     // console.log("ta incluyendo")
  //     try{this.routeHomeRef.current.click()}catch (err){console.log(err)}
  // }
    


}

selectStep=(step)=>{
  let currentStep = {...this.state.steps}
  currentStep.selectedStep=step
  this.setState({steps:currentStep});
}
logout = () =>{
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userId');

this.setState({fetchedUser:false,logged:false,user:null,selected:'home'})
try{this.routeHomeRef.current.click()}catch (err){console.log(err)}




}
deleteListing=(id)=>{
  this.virtualDeleteListing(id)

  this.state.myListing.listingPictures.forEach( picture => {
    axios.delete(picture.url)
  });
  const token = window.localStorage.getItem('token')
  axios.defaults.headers.delete['Authorization']="JWT "+token
  axios.delete(baseUrl + `api/listings/`+id).then(resp=>{
    //this.reFetchUser()
  })
  
}

getUser=()=>{
  const token = window.localStorage.getItem('token')
  let userRequest=async function(){return null}
  let neoAxios ={...axios}
  if(token){
    axios.defaults.headers.get['Authorization']="JWT "+token
    userRequest = axios.get(baseUrl + `current_user/`)
  }
  axios.defaults.headers.get['Content-Type']='application/x-www-form-urlencoded'
  // const featuresRequest = axios.get(baseUrl +"getFeatures/")
  // const statesRequest = axios.get(baseUrl +"getStates/")
  // const countriesRequest = axios.get(baseUrl +"getCountries/")
  // const results = axios.post(baseUrl+"getResults/",{"locationCode":null})
  
  // const featuresRequest = axios.get(baseUrl +"getFeatures/")
  
  neoAxios.defaults.proxy = {host:"https://cors-anywhere.herokuapp.com"}
  delete neoAxios.defaults.headers.get['Authorization'];
  const currenciesReq = neoAxios.get("https://api.exchangerate-api.com/v4/latest/USD")
  const fetchCol = neoAxios.get("https://costosback.herokuapp.com/fetchCol")
  Promise.all([userRequest,currenciesReq,fetchCol,]).then(res => {
    const data = res[0].data;
  
    const currenciesData=res[1].data;
    const col=res[2].data;
      // const rawResults=res[2].data;
    console.log(currenciesData,"data currencies")
    let newSteps = {...this.state.steps}
    //newSteps.options.features=features
    //Notifications and Chat set up
    // if(data.is_superuser){this.fetchSupportChats()}
    let switcher;
    let neoCurrency = {...this.state.currency}
    neoCurrency.rates=currenciesData.rates
    data?switcher=true:switcher=false
    this.setState({user:data},()=>{
      this.setState({fetchedUser:switcher,logged:switcher,currency:neoCurrency,
        dialogFetching:false,steps:newSteps,collection:col})
      
      /*
      const rates = res[1].data.rates
      let currency = {...this.state.currency}
      currency.rate = rates[currency.preferred]
      currency.rates = {"DOP":rates["DOP"],"EUR":rates["EUR"]}
      this.setState({currency:currency})
      */
    })
  }).catch(error=>{console.log("error getting user",error)})
}
fetchElements=()=>{
delete axios.defaults.headers.get['Authorization'];
axios.defaults.headers.get['Content-Type']='application/x-www-form-urlencoded'
const featuresRequest = axios.get(baseUrl +"getFeatures/")
const statesRequest = axios.get(baseUrl +"getStates/")
const countriesRequest = axios.get(baseUrl +"getCountries/")
const myBooking =this.state.myBooking
let endDate
let startDate
if(myBooking.endDate===null || myBooking.length<9){
  endDate=moment().add(7,'d')
}else{
  endDate=moment(myBooking.endDate)
}
if(myBooking.startDate===null || myBooking.length<9){
  startDate=moment()
}else{
  startDate=moment(myBooking.startDate)
}
let counter=0

Promise.all([statesRequest,countriesRequest,featuresRequest]).then(res => {
  const features = res[3].data;
  const states = res[0].data;
  const countries = res[1].data;
  const results = res[2].data;

  //console.log("fetched data", states, countries,"resuts",results)
  this.setState({elements:{features:features,states:states,countries:countries}})
  this.setState({rawResults:results})
  counter=counter+1
  if(counter>1){
    counter=-1
    this.setState({fetched:true})
  }
})
axios.defaults.proxy = {host:"https://cors-anywhere.herokuapp.com"}
axios.get("https://api.exchangerate-api.com/v4/latest/USD").then(
  resp=>{
    let currency={selected:"DOP",rates:resp.data.rates,symbols:currencySymbols}
    //console.log("respuesta covnersion monedas",currency)
    this.setState({currency:currency})
    counter=counter+1
    if(counter>1){
      counter=-1
      this.setState({fetched:true})
    }
    axios.defaults.proxy = null
  }
).catch(err=>{
  console.log("error con api monedas",err)
  console.log("using mock up data")
  let currency={selected:"DOP",symbols:currencySymbols,rates:{"USD":1,"AED":3.672058,"ARS":60.073152,"AUD":1.462619,"BGN":1.772324,"BRL":4.175311,"BSD":1,"CAD":1.313949,"CHF":0.970542,"CLP":775.032232,"CNY":6.937035,"COP":3356.26087,"CZK":22.774105,"DKK":6.769282,"DOP":53.200551,"EGP":15.731404,"EUR":0.906153,"FJD":2.173377,"GBP":0.763843,"GTQ":7.686349,"HKD":7.772394,"HRK":6.743727,"HUF":304.680441,"IDR":13481.299912,"ILS":3.454776,"INR":71.322065,"ISK":124.593825,"JPY":109.43859,"KRW":1168.893381,"KZT":378.401961,"MXN":18.789109,"MYR":4.06712,"NOK":9.020184,"NZD":1.513791,"PAB":1,"PEN":3.322153,"PHP":50.850544,"PKR":154.697395,"PLN":3.850458,"PYG":6432.833333,"RON":4.330082,"RUB":61.891661,"SAR":3.750937,"SEK":9.552551,"SGD":1.351475,"THB":30.561937,"TRY":5.940419,"TWD":30.040088,"UAH":24.306582,"UYU":37.255792,"ZAR":14.392269}}
  this.setState({currency:currency})
  counter=counter+1
  if(counter>1){
    counter=-1
    this.setState({fetched:true})
  }
  axios.defaults.proxy = null
})
}

   componentDidMount(){
    this.position()
    this.getUser()
    this.updateDimensions()
    document.querySelector('html').addEventListener('keydown',this.processKey)
    window.addEventListener('scroll',this.handleScroll)
    window.addEventListener("resize", this.updateDimensions);
   }
   componentWillUnmount(){
    document.querySelector('html').removeEventListener('keydown')
    window.removeEventListener('scroll')
  }
   switchLoginModal=(newModal)=>{
     try {
      let navElement=document.querySelector('#root > div > div.navBar');
      if(newModal.active){
        
        navElement.style.position="relative"
      }else{
       navElement.style.position="fixed"
      }
     } catch (error) {
       //pass
     }
    
     this.setState({loginModal:newModal})
   }
   switchRent=(e,f=null)=>{
    let prevVal
    console.log(e)
    if(f){
      prevVal= true
    }else{
      if(!f){
        console.log("falso")
        prevVal= false
      }else{
        prevVal= !this.state.rent
      }
      
    }
    this.setState({rent:prevVal})
   }
   position = async () => {
    const that = this
    await navigator.geolocation.getCurrentPosition(
      position => {
        console.log(position,"position")
        that.setState({ 
        latitude: position.coords.latitude, 
        longitude: position.coords.longitude
      })}, 
      err => {
        that.setState({ 
          latitude: 18.4637770,
          longitude: -69.9336969
        })
        console.log("error ubicando cliente",err)}
    );
    console.log("lattitude",this.state.latitude)
    
  }

  switchGeneralModal=(generalModal)=>{
    this.setState({generalModal})
    return
  }
  updateUser=(user)=>{
    this.setState({user})
  }
  render() {
    const refs={    routeProfileRef:this.routeProfileRef,
      routeChatRef:this.routeChatRef,
      routeHomeRef:this.routeHomeRef,
      routeListingsRef:this.routeListingsRef,
      routeSavedRef:this.routeSavedRef,
      routeLoginMobileRef:this.routeLoginMobileRef}
    const methods={login:this.login,logout:this.logout,formatNumber,neoSelected:this.neoSelected,
      handleRegister:this.handleRegister,switchGeneralModal:this.switchGeneralModal,updateUser:this.updateUser,
      newMaterial:this.newMaterial,newTool:this.newTool,newFerreteria:this.newFerreteria,newCollection:this.newCollection,
      newRole:this.newRole,newAnalysis:this.newAnalysis,newPresupuesto:this.newPresupuesto,
      calcularAnalysis:this.calcularAnalysis,calcularPresupuesto:this.calcularPresupuesto,delLine:this.delLine,
      newCategory:this.newCategory,newPartida:this.newPartida,newMaterialInput:this.newMaterialInput,
      newToolInput:this.newToolInput,newMOInput:this.newMOInput,closeModal:this.closeModal,newAnalysisInput:this.newAnalysisInput,
      
    }
    const userPack={user:this.state.user,axiosPack:this.state.axiosPack,latitude:this.state.latitude,
      longitude:this.state.longitude, dimensions:this.state.dimensions,refs,gerenalModal:this.state.gerenalModal,
      loginModal:this.state.loginModal,methods:methods,selected:this.state.selected,collection:this.state.collection,
      rent:this.state.rent,switchRent:this.switchRent,currency:this.state.currency,rawResults:this.state.rawResults,
      switchLoginModal:this.switchLoginModal}
    return ( 
      <>
      {this.state.loginModal.active?<LoginModal userPack={userPack}/>:null}

      <Link to="profile" className="buttonClass" ref={this.routeProfileRef}/>
      <div style={{backgroundColor:"white",minHeight:"100vh"}}>
      <Router>
       
         <MainView path="/" userPack={userPack} />
         <DashBoard path="/dashboard" userPack={userPack} />
         <LoginMobile path="/loginMobile" userPack={userPack} />
         <Presupuesto path="/presupuesto/:presupuestoId" userPack={userPack}/>
         <Analysis path="/analysis/:analysisId" userPack={userPack}/>
         <Material path="/material/:materialId" userPack={userPack}/>
         <Tool path="/tool/:toolId" userPack={userPack}/>
         <MO path="/mo/:moId" userPack={userPack}/>
         <Presupuestos path="/presupuesto" userPack={userPack}/>
         <Analysiss path="/analysis" userPack={userPack}/>
         <Materials path="/material" userPack={userPack}/>
         <Tools path="/tool" userPack={userPack}/>
         <MOs path="/mo" userPack={userPack}/>
         <Explore  path="/explore" userPack={userPack}/>
         <Collections  path="/collection" userPack={userPack}/>
         <Ferreterias  path="/ferreteria" userPack={userPack}/>
         <Collection path="/collection/:collectionId" userPack={userPack}/>
         <Ferreteria path="/ferreteria/:ferreteriaId" userPack={userPack}/>
      </Router>
      
      </div>
     
      <Link to="chat" className="buttonClass" ref={this.routeChatRef}/>
      <Link to="saved" className="buttonClass" ref={this.routeSavedRef}/>
      <Link to="/" className="buttonClass" ref={this.routeHomeRef}/>
      <Link to="dashboard" className="buttonClass" ref={this.routeListingsRef}/>
      <Link to="loginMobile" className="buttonClass" ref={this.routeLoginMobileRef}/>
  </>
     );
  }
}
 
export default App;
