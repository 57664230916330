import React from 'react';
import TableLine from './TableLine';
import DashNavBar from '../elements/DashNavBar';
import DashSearch from './DashSearch';
import Loader from '../elements/Loader';

export default function(props){
    let selectedAnalysis
    let valores
    let neoMat
    if(props.userPack.user&&props.userPack.collection.analysiss){
        let list={...props.userPack.user}.analysiss.concat(props.userPack.collection.analysiss)
        selectedAnalysis = list.filter(c=>(c.id==props.analysisId))[0]
        valores = props.userPack.methods.calcularAnalysis(1,selectedAnalysis)
        neoMat = selectedAnalysis.matList.concat(selectedAnalysis.analysisList)
    }
    
    const newMaterialInput=()=>{
        let newVal = {...props.userPack.loginModal}
        newVal.active=true
        newVal.analysis=props.analysisId
        newVal.material={quantity:''}
        newVal.type="newMaterialInput"
        props.userPack.switchLoginModal(newVal)
        //props.userPack.methods.newMaterialInput(props.analysisId,13,1)
    }
    const newMOInput=()=>{
        let newVal = {...props.userPack.loginModal}
        newVal.active=true
        newVal.analysis=props.analysisId
        newVal.material={quantity:''}
        newVal.type="newMOInput"
        props.userPack.switchLoginModal(newVal)
    }
    const newToolInput=()=>{
        let newVal = {...props.userPack.loginModal}
        newVal.active=true
        newVal.analysis=props.analysisId
        newVal.material={quantity:''}
        newVal.type="newToolInput"
        props.userPack.switchLoginModal(newVal)
        // props.userPack.methods.newToolInput(props.analysisId,4,1)
    }
    return(
        <>
        <DashNavBar userPack={props.userPack}/>
        <DashSearch route="newAnalysis" userPack={props.userPack} newMethod={()=>{}}/>
        {props.userPack.user&&props.userPack.collection.analysiss?
        <>
        <div className="alignCenter flexStart" style={{marginTop:"10px"}}>   
            <img className="" style={{marginLeft:"2rem",width:"50px",height:"50px"}} src="https://civiltools.club/media/analysis.svg" alt=""/> 
            <h1 className="mainContentTitle" >
                {selectedAnalysis.name} ({selectedAnalysis.unit})
                </h1>
             </div>
                  <div className="alignCenter justify" style={{marginTop:"15px",marginBottom:"2.6rem"}}>
         <div className="tableBox">
            <div className="table">
                <div className="mainLine tableFormat">
                     <p className="nivelesTitle textAlign mainLine">Divisor: {selectedAnalysis.divisor}</p> 
                     <p className="nivelesTitle textAlign mainLine">Ud</p>
                     <p className="nivelesTitle textAlign mainLine">Qty</p>
                     <p className="nivelesTitle textAlign mainLine">Costo</p>
                     <p className="nivelesTitle textAlign mainLine">Total</p>
                </div>

                <div className="deleteLine green zsup alignCenter" style={{height:"30px"}}>
                     <p className="mainLineText">1. Materiales</p> <button onClick={newMaterialInput} style={{marginLeft:"8px",width:"80px"}} class="pure-material-button-contained alignCenter justify deleteIcon">Agregar</button>
                     
                </div>
                {selectedAnalysis.matList.map((c,index)=>(
                    <TableLine lineId={c.id} userPack={props.userPack} format={props.userPack.methods.formatNumber} type="material" quantity={c.quantity} name={c.material.name} partida={c.material} dark={index%2===0?true:false}/>
                ))}
                                {selectedAnalysis.analysisList.map((c,index)=>(
                    <TableLine lineId={c.id} userPack={props.userPack} format={props.userPack.methods.formatNumber} type="analysis" quantity={c.quantity} name={c.analysisBase.name} analysis={c.analysisBase} dark={index%2===0?true:false}/>
                ))}
                <div className={neoMat.length%2===0?"tableFormat2 zsup dargGrey":"tableFormat2 lightGrey zsup"}>
                        <div className="alignCenter justify zsupp">
                         <p className="mainLineTextBlack" style={{color:"white !important"}}>Subtotal</p>
                        </div>
                        <div className="alignCenter justify zsupp" >
                            <p className="">RD$ {props.userPack.methods.formatNumber(parseFloat(valores.matTotal).toFixed(2))}</p>
                        </div>
                        
                    </div>
                <div className="green zsup alignCenter deleteLine" style={{height:"30px"}}>
                     <p className="mainLineText ">2. Mano de Obra</p>  <button  onClick={newMOInput} style={{marginLeft:"8px",width:"80px"}} class="pure-material-button-contained alignCenter justify deleteIcon">Agregar</button>
                     
                </div>
                {selectedAnalysis.moList.map((c,index)=>(
                     <TableLine lineId={c.id} userPack={props.userPack} format={props.userPack.methods.formatNumber} type="trabajador" quantity={c.quantity} name={c.trabajador.name} partida={c.trabajador} dark={index%2===0?true:false}/>
                ))}
                <div className={selectedAnalysis.moList.length%2===0?"tableFormat2 zsup dargGrey":"tableFormat2 lightGrey zsup"}>
                        <div className="alignCenter justify zsupp">
                         <p className="mainLineTextBlack" style={{color:"white !..important"}}>Subtotal</p>
                        </div>
                        <div className="alignCenter justify zsupp" >
                            <p className="">RD$ {props.userPack.methods.formatNumber(parseFloat(valores.moTotal).toFixed(2))}</p>
                        </div>
                        
                    </div>
                <div className="green zsup alignCenter deleteLine" style={{height:"30px"}}>
                     <p className="mainLineText">3. Equipos y herramientas</p>  <button  onClick={newToolInput} style={{marginLeft:"8px",width:"80px"}} class="pure-material-button-contained alignCenter justify deleteIcon">Agregar</button>
                     
                </div>
                {selectedAnalysis.toolList.map((c,index)=>(
                     <TableLine lineId={c.id} userPack={props.userPack} format={props.userPack.methods.formatNumber} type="tool" quantity={c.quantity} name={c.tool.name} partida={c.tool} dark={index%2===0?true:false}/>
                ))}
                    <div className={selectedAnalysis.toolList.length%2===0?"tableFormat2 zsup dargGrey":"tableFormat2 lightGrey zsup"}>
                        <div className="alignCenter justify zsupp">
                         <p className="mainLineTextBlack" style={{color:"white !important"}}>Subtotal</p>
                        </div>
                        <div className="alignCenter justify zsupp" >
                            <p className="">RD$ {props.userPack.methods.formatNumber(parseFloat(valores.toolTotal).toFixed(2))}</p>
                        </div>
                        
                    </div>
            </div>
            <div className="bottomTable alignCenter justify">
                <p className="bottomTitle textAlign" style={{padding:"18px"}}>Costo unitario: RD$ {props.userPack.methods.formatNumber(parseFloat(valores.total/selectedAnalysis.divisor).toFixed(2))}</p>

                </div>
        </div>
        </div>
        </>
        :
        <div className="alignCenter justify" style={{paddingTop:"100px"}}>
            <Loader />
        </div>
        }
        
        </>
    )
}